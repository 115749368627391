const DATA_KEY = '@newPlot/AUTH';
const USER_KEY = '@newPlot/USER';

export const isAuthenticated = () => localStorage.getItem(DATA_KEY) !== null;

export const getLoginData = () => isAuthenticated() && JSON.parse(localStorage.getItem(DATA_KEY));
export const getUserLogged = () => isAuthenticated() && JSON.parse(localStorage.getItem(USER_KEY));

export const login = data => {
  localStorage.setItem(DATA_KEY, JSON.stringify({
    signed: data.signed,
    token: data.token.token
  }));
  localStorage.setItem(USER_KEY, JSON.stringify(data.user));
};
export const logout = () => {
  localStorage.removeItem(DATA_KEY);
  localStorage.removeItem(USER_KEY);
};
export const updateUserLogged = user => {
  localStorage.setItem(USER_KEY, JSON.stringify(user));
}
