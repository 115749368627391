import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import moment from 'moment';
import uuid from 'uuid/v1';

import api from '../../../services/api';
import { formatPrice, reverseFormatPrice } from '../../../util/formatCurrency';

import AddProduct from './components/AddProduct';
import AddProductCustom from './components/AddProductCustom';

const useStyles = makeStyles(theme => ({
  buttonAddProductCustom: {
    background: '#1e88e5',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#42a5f5',
      borderColor: '#0062cc'
    },
    flexGrow: 0.5
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  }
}));

export default function Form({
  openForm,
  setOpenForm,
  data,
  setData,
  loadData,
  products,
  customers
}) {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [date, setDate] = useState('');
  const [selectedProduct, setSelectedProduct] = useState({});
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [fields, setFields] = useState([]);
  const [fieldsCustom, setFieldsCustom] = useState([]);
  const [totalBudget, setTotalBudget] = useState('');
  const [totalDiscount, setTotalDiscount] = useState({
    discountPercentage: '',
    discountedValue: '',
    discountAmount: ''
  });

  useEffect(() => {
    const customerSelected = customers.find(
      item => item.id === data.values.cliente
    );

    setSelectedCustomer(customerSelected);
  }, [data.values.cliente, customers]);

  useEffect(() => {
    function getCurrentDate() {
      openForm
        ? setDate(moment(new Date()).format('DD/MM/YYYY HH:mm:ss'))
        : resetState();
    }

    getCurrentDate();
    // eslint-disable-next-line
  }, [openForm]);

  useEffect(() => {
    function calculateTotalBudget() {
      const total = fields.concat(fieldsCustom);

      const calculatedValue = total.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.price * currentValue.quantity;
      }, 0);

      setTotalBudget(formatPrice(calculatedValue));
    }
    calculateTotalBudget();
    // eslint-disable-next-line
  }, [fields, fieldsCustom]);

  useEffect(() => {
    function percentCalculation() {
      const totalBudgetConverted = reverseFormatPrice(totalBudget);
      const { discountPercentage } = totalDiscount;

      const calculatedValue = (totalBudgetConverted * discountPercentage) / 100;

      const discount = totalBudgetConverted - calculatedValue;

      setTotalDiscount({
        ...totalDiscount,
        discountedValue: formatPrice(discount),
        discountAmount: calculatedValue
      });
    }
    percentCalculation();
    // eslint-disable-next-line
  }, [totalDiscount.discountPercentage, totalBudget]);

  function handleClose() {
    return setOpenForm(false);
  }

  async function resetState() {
    await setDate('');
    await setData({
      isValid: false,
      values: {},
      touched: {},
      errors: {}
    });
    await setFields([]);
    await setFieldsCustom([]);
    await setDisabled(true);
    setTotalDiscount({
      discountPercentage: '',
      discountedValue: '',
      discountAmount: ''
    });
  }

  async function handleProductSelected(productSelected) {
    if (productSelected === '') {
      setDisabled(true);
    } else {
      setDisabled(false);
      const { id, price, name, priceFormatted } = JSON.parse(productSelected);

      setSelectedProduct({ id, price, name, priceFormatted, quantity: 1 });
    }
  }

  function handleChange(event) {
    event.persist();

    setData(data => ({
      ...data,
      values: {
        ...data.values,
        [event.target.name]: event.target.value
      },
      touched: {
        ...data.touched,
        [event.target.name]: true
      }
    }));
  }

  function handleAddProduct() {
    let newArray = [...fields];
    const { id, price, name, quantity, priceFormatted } = selectedProduct;

    const checkExistenceValues = fields.findIndex(item => item.id === id);

    if (checkExistenceValues >= 0) {
      toast.info(
        'O produto já está adicionado, se desejar aumente a quantidade por favor.'
      );
    } else {
      newArray.push({
        id,
        name,
        price,
        quantity,
        priceFormatted
      });
      setFields(newArray);
    }
  }

  function handleAddProductCustom() {
    const values = [...fieldsCustom];
    values.push({
      id: uuid(),
      name: '',
      price: 0,
      quantity: 1
    });
    setFieldsCustom(values);
  }

  function handleRemoveProduct(id) {
    const valuesFiltered = id => fields.filter(item => item.id !== id);
    const values = valuesFiltered(id);
    setFields(values);
  }

  function handleRemoveProductCustom(id) {
    const valuesFiltered = id => fieldsCustom.filter(item => item.id !== id);
    const values = valuesFiltered(id);
    setFieldsCustom(values);
  }

  function formatValueTotal(amount, discount) {
    const amountConverted = reverseFormatPrice(amount);
    const discountConverted = discount ? parseFloat(discount).toFixed(2) : 0;
    const total = amountConverted - discountConverted;
    return total;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    try {
      setLoading(true);

      const { cliente, solicitante, obra } = data.values;

      const dataSent = {
        status: 0,
        approval_date: '',
        creation_date: moment(date, 'DD/MM/YYYY').format('YYYY/MM/DD'),
        customer_id: cliente,
        amount: formatValueTotal(totalBudget, totalDiscount.discountAmount),
        products: fields,
        custom_products: fieldsCustom,
        discount_percentage: totalDiscount.discountPercentage,
        discount_amount: parseFloat(totalDiscount.discountAmount).toFixed(2),
        requester_id: solicitante,
        construction_id: obra
      };

      const response = await api.post('/budgets', dataSent);

      if (response.data.success) {
        toast.success(response.data.success.message);
      }
    } catch (error) {
      if (error.response) {
        error.response.data.map(error => toast.error(error.message));
      } else {
        toast.error('Error interno, por favor tente em instantes.');
      }
    } finally {
      handleClose();
      setData({
        isValid: false,
        values: {},
        touched: {},
        errors: {}
      });
      setLoading(false);
      loadData();
    }
  }

  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
      onClose={handleClose}
      open={openForm}
      scroll="body">
      <DialogTitle id="form-dialog-title">Cadastro de orçamento</DialogTitle>
      <form data-testid="product-form" onSubmit={handleSubmit}>
        <DialogContent>
          <DialogContentText>
            Informe os seguintes dados para prosseguir.
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography>Data de criação: {date}</Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid
                alignItems="center"
                container
                direction="row"
                justify="space-between"
                spacing={2}>
                <Grid item lg={7} md={7} sm={7} xs={12}>
                  <Typography>Total do orçamento: {totalBudget}</Typography>
                  <Typography>
                    Total o orçamento com desconto:{' '}
                    {totalDiscount.discountedValue || ''}
                  </Typography>
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={12}>
                  <TextField
                    fullWidth
                    // eslint-disable-next-line
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      )
                    }}
                    // eslint-disable-next-line
                    InputLabelProps={{
                      shrink: true
                    }}
                    // eslint-disable-next-line
                    inputProps={{ 'data-testid': 'input-discount' }}
                    label="Desconto"
                    margin="dense"
                    onChange={event =>
                      setTotalDiscount({
                        ...totalDiscount,
                        discountPercentage: event.target.value
                      })
                    }
                    type="number"
                    value={totalDiscount.discountPercentage}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextField
                fullWidth
                inputProps={{ 'data-testid': 'input-client' }}
                label="Cliente"
                margin="dense"
                name="cliente"
                onChange={handleChange}
                required
                select
                // eslint-disable-next-line
                SelectProps={{ native: true }}
                // eslint-disable-next-line
                InputLabelProps={{
                  shrink: true
                }}
                value={data.values.cliente || ''}
                variant="outlined">
                <option value={{}}>Selecionar</option>
                {customers.map((option, idx) => (
                  <option key={idx} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </TextField>
            </Grid>
            {selectedCustomer &&
              selectedCustomer.requesters &&
              selectedCustomer.requesters.length > 0 &&
              selectedCustomer.company === 1 && (
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    fullWidth
                    inputProps={{ 'data-testid': 'input-client' }}
                    label="Solicitante"
                    margin="dense"
                    name="solicitante"
                    onChange={handleChange}
                    required
                    select
                    // eslint-disable-next-line
                    SelectProps={{ native: true }}
                    // eslint-disable-next-line
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={data.values.solicitante || ''}
                    variant="outlined">
                    <option value={{}}>Selecionar</option>
                    {selectedCustomer.requesters.map((option, idx) => (
                      <option key={idx} value={option.id}>
                        {option.name_requester}
                      </option>
                    ))}
                  </TextField>
                </Grid>
              )}
            {selectedCustomer &&
              selectedCustomer.constructions &&
              selectedCustomer.constructions.length > 0 && (
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    fullWidth
                    inputProps={{ 'data-testid': 'input-client' }}
                    label="Obra"
                    margin="dense"
                    name="obra"
                    onChange={handleChange}
                    required
                    select
                    // eslint-disable-next-line
                    SelectProps={{ native: true }}
                    // eslint-disable-next-line
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={data.values.obra || ''}
                    variant="outlined">
                    <option value={{}}>Selecionar</option>
                    {selectedCustomer.constructions.map((option, idx) => (
                      <option key={idx} value={option.id}>
                        {option.name_construction}
                      </option>
                    ))}
                  </TextField>
                </Grid>
              )}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid alignItems="center" container direction="row" spacing={1}>
                <Grid item lg={3} md={3} sm={3} xs={12}>
                  <TextField
                    className={classes.textField}
                    // eslint-disable-next-line
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{ 'data-testid': 'input-products' }}
                    label="Produtos"
                    margin="dense"
                    name="products"
                    onChange={event =>
                      handleProductSelected(event.target.value)
                    }
                    required
                    select
                    // eslint-disable-next-line
                    SelectProps={{ native: true }}
                    variant="outlined">
                    <option value={{}}>Selecionar</option>
                    {products.data.map((option, idx) => (
                      <option key={idx} value={JSON.stringify(option)}>
                        {option.name} - {option.priceFormatted}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <Button
                    color="primary"
                    disabled={disabled}
                    // eslint-disable-next-line
                    disableFocusRipple
                    fullWidth
                    onClick={handleAddProduct}
                    size="medium"
                    variant="contained">
                    <AddIcon className={classes.extendedIcon} />
                    Adicionar produto
                  </Button>
                </Grid>
                <Grid item lg={5} md={5} sm={5} xs={12}>
                  <Button
                    className={classes.buttonAddProductCustom}
                    color="primary"
                    disableFocusRipple
                    fullWidth
                    onClick={handleAddProductCustom}
                    size="medium"
                    variant="contained">
                    <AddIcon className={classes.extendedIcon} />
                    Adicionar produto personalizado
                  </Button>
                </Grid>
                {fields.map((field, idx) => {
                  return (
                    <AddProduct
                      field={field}
                      fields={fields}
                      handleRemoveProduct={handleRemoveProduct}
                      key={`${field}-${idx}`}
                      setFields={setFields}
                    />
                  );
                })}
                {fieldsCustom.map((field, idx) => {
                  return (
                    <AddProductCustom
                      field={field}
                      fieldsCustom={fieldsCustom}
                      handleRemoveProductCustom={handleRemoveProductCustom}
                      key={`${field}-${idx}`}
                      setFieldsCustom={setFieldsCustom}
                    />
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button color="primary" disabled={loading} type="submit">
            {loading ? <CircularProgress /> : 'Cadastrar'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

Form.propTypes = {
  customers: PropTypes.array,
  data: PropTypes.shape(),
  loadData: PropTypes.func,
  openForm: PropTypes.bool,
  products: PropTypes.shape(),
  setData: PropTypes.func,
  setOpenForm: PropTypes.func
};
