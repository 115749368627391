import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { PDFViewer } from '@react-pdf/renderer';
import { toast } from 'react-toastify';
import 'moment/locale/pt-br';

import TabPanel from '../TabPanel';
import PdfDocument from './PdfDocument';

import api from '../../../../services/api';
import { formatPrice } from '../../../../util/formatCurrency';

const locale = moment.locale('pt-br');

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  button: {
    marginLeft: theme.spacing(1),
  },
}));

export default function FourthTab({ theme, index, value }) {
  const classes = useStyles();

  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());

  async function handleGenerateReport() {
    let year = moment(selectedDate).format('YYYY');
    let month = moment(selectedDate).format('MM');

    try {
      const response = await api.get(`reports/general/outputs/year/${year}/month/${month}`);
      if (response.data.error) {
        toast.info(response.data.error.message);
        setShow(false);
      } else {
        setData({
          data: response.data.exits.map(exit => ({
            ...exit,
            formattedCreationDate: moment(exit.date).format('DD/MM/YYYY'),
            formattedValue: formatPrice(exit.price)
          })),
          amountTotal: formatPrice(response.data.total),
          dateFormatted: moment(new Date()).format('DD/MM/YYYY')
        });
        setShow(true);
      }
    } catch (error) {
      toast.error('Falha ao gerar relatório, por favor tente em instantes');
      setShow(false);
    }
  }

  const handleMenuOpen = useCallback(e => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  }, []);

  return (
    <TabPanel
      dir={theme}
      index={index}
      value={value}
    >
      <MuiPickersUtilsProvider
        libInstance={moment}
        locale={locale}
        utils={MomentUtils}>
        <DatePicker
          InputProps={{
            endAdornment: (
              <IconButton
                aria-label="Select locale"
                aria-owns={anchorEl ? 'locale-menu' : undefined}
                onClick={handleMenuOpen}
              />
            ),
          }}
          inputVariant="outlined"
          onChange={date => setSelectedDate(date)}
          value={selectedDate}
          variant="dialog"
          views={['year', 'month']}
        />
      </MuiPickersUtilsProvider>
      <Button
        className={classes.button}
        color="primary"
        disableFocusRipple
        onClick={handleGenerateReport}
        variant="contained"
      >
        Gerar relatório
        <PictureAsPdfIcon className={classes.rightIcon} />
      </Button>
      {show && <PDFViewer
        children={<PdfDocument
          data={data}
          month={moment(selectedDate).format('MMMM').toUpperCase()} />}
        height="700px"
        style={{ marginTop: 10 }}
        width="100%"
      />}
    </TabPanel>
  );
}

FourthTab.propTypes = {
  index: PropTypes.any.isRequired,
  theme: PropTypes.object,
  value: PropTypes.any.isRequired,
};
