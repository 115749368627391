import React from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import Fab from '@material-ui/core/Fab';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ClearIcon from '@material-ui/icons/Clear';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  buttonRemove: {
    background: '#ff1744',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#ff5252',
      borderColor: '#ff1744'
    }
  }
});

export default function AddProductCustom({
  setFieldsCustom,
  handleRemoveProductCustom,
  fieldsCustom,
  field
}) {
  const classes = useStyles();

  function handleAddProductCustom(event) {
    let newArray = [...fieldsCustom];
    const { id } = field;

    const checkExistenceValues = newArray.findIndex(item => item.id === id);

    if (checkExistenceValues >= 0) {
      newArray[checkExistenceValues] = {
        ...newArray[checkExistenceValues],
        [event.target.name]: event.target.value
      };
      setFieldsCustom(newArray);
    }
  }

  function handleIncrement(id) {
    setFieldsCustom(
      fieldsCustom.map(item => {
        if (item.id === id) {
          return {
            ...item,
            pivot: { ...item.pivot, quantity: item.pivot.quantity + 1 }
          };
        } else {
          return item;
        }
      })
    );
  }

  function handleDecrement(id) {
    setFieldsCustom(
      fieldsCustom.map(item => {
        if (item.id === id && item.pivot.quantity > 1) {
          return {
            ...item,
            pivot: { ...item.pivot, quantity: item.pivot.quantity - 1 }
          };
        } else {
          return item;
        }
      })
    );
  }

  return (
    <Grid
      alignItems="center"
      container
      direction="row"
      justify="space-between">
      <Grid
        item
        lg={7}
        md={7}
        sm={7}
        xs={12}>
        <Grid
          alignItems="center"
          container
          direction="row"
          justify="space-between">
          <TextField
            className={classes.textField}
            // eslint-disable-next-line
            InputLabelProps={{
              shrink: true
            }}
            inputProps={{ 'data-testid': 'input-product-name' }}
            label="Produto"
            margin="dense"
            name="name"
            onChange={handleAddProductCustom}
            required
            value={field.name || ''}
            variant="outlined"
          />
          <TextField
            // eslint-disable-next-line
            InputLabelProps={{
              shrink: true
            }}
            inputProps={{ 'data-testid': 'input-products' }}
            // eslint-disable-next-line
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">R$</InputAdornment>
              )
            }}
            label="Preço"
            margin="dense"
            name="price"
            onChange={handleAddProductCustom}
            required
            type="number"
            value={field.price || ''}
            variant="outlined"
          />
        </Grid>
      </Grid>
      <Grid
        item
        lg={5}
        md={5}
        sm={5}
        xs={12}>
        <Grid
          alignItems="center"
          container
          direction="row"
          justify="space-evenly">
          <Fab
            aria-label="add"
            color="default"
            disableFocusRipple
            onClick={() => handleIncrement(field.id)}
            size="small">
            <AddIcon />
          </Fab>
          <Typography>{field.pivot.quantity}</Typography>
          <Fab
            aria-label="add"
            color="default"
            disableFocusRipple
            onClick={() => handleDecrement(field.id)}
            size="small">
            <RemoveIcon />
          </Fab>
          <Fab
            aria-label="remove"
            className={classes.buttonRemove}
            disableFocusRipple
            onClick={() => handleRemoveProductCustom(field.id)}
            size="small">
            <ClearIcon />
          </Fab>
        </Grid>
      </Grid>
    </Grid>
  );
}

AddProductCustom.propTypes = {
  field: PropTypes.object,
  fieldsCustom: PropTypes.array,
  handleRemoveProductCustom: PropTypes.func,
  setFieldsCustom: PropTypes.func
};
