import React from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ClearIcon from '@material-ui/icons/Clear';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  textField: {
    width: 600,
  },
  quantity: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonRemove: {
    background: '#ff1744',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#ff5252',
      borderColor: '#ff1744',
    },
  },
});

export default function AddProduct({ handleRemoveProduct, field, setFields, fields }) {
  const classes = useStyles();

  function handleIncrement(id) {
    setFields(fields.map(item => {
      if (item.id === id) {
        return {...item, quantity: item.quantity + 1}
      } else {
        return item
      }
    }))
  }
  
  function handleDecrement(id) {
    setFields(fields.map(item => {
      if (item.id === id && item.quantity > 1) {
        return {...item, quantity: item.quantity - 1}
      } else {
        return item
      }
    })) 
  }

  return (
    <Grid
      alignItems="center" 
      container
      direction="row"
      justify="space-between"
    >
      <Grid
        item
        lg={7}
        md={7}
        sm={7}
        xs={12}
      >
        <Grid
          alignItems="center"
          container
          direction="row"
          justify="space-between"
        >
          <TextField
            className={classes.textField}
            disabled
            // eslint-disable-next-line
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ 'data-testid': 'input-product-name' }}
            label="Produto"
            margin="dense"
            name="products"
            value={`${field.name} - ${field.priceFormatted}`}
            variant="outlined"
          />
        </Grid>
      </Grid>
      <Grid
        item
        lg={5}
        md={5}
        sm={5}
        xs={12}
      >
        <Grid
          alignItems="center"
          container
          direction="row"
          justify="space-evenly"
        >
          <Fab
            aria-label="add"
            color="default"
            disableFocusRipple
            onClick={() => handleIncrement(field.id)}
            size="small"
          >
            <AddIcon />
          </Fab>
          <Typography
            className={classes.quantity}
          > {field.quantity} </Typography>
          <Fab
            aria-label="add"
            color="default"
            disableFocusRipple
            onClick={() => handleDecrement(field.id)}
            size="small"
          >
            <RemoveIcon />
          </Fab>
          <Fab
            aria-label="remove"
            className={classes.buttonRemove}
            disableFocusRipple
            onClick={() => handleRemoveProduct(field.id)}
            size="small"
          >
            <ClearIcon />
          </Fab>
        </Grid>
      </Grid>
    </Grid>
  );
}

AddProduct.propTypes = {
  field: PropTypes.object,
  fields: PropTypes.array,
  handleRemoveProduct: PropTypes.func,
  setFields: PropTypes.func,
};
