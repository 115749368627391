import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  CircularProgress
} from '@material-ui/core';
import { toast } from 'react-toastify';
import 'moment/locale/pt-br';
import validate from 'validate.js';

import api from '../../../../services/api';
import { updateUserLogged } from '../../../../services/auth';

const schema = {
  senhaAtual: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  novaSenha: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  confirmarNovaSenha: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
    equality: {
      attribute: 'novaSenha',
      message: 'não é igual a %{attribute}'
    }
  },
};

const useStyles = makeStyles(() => ({
  buttonSubmit: {
    width: '100%'
  }
}));

export default function ChangePassword({className, password, setPassword, ...rest}) {

  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const errors = validate(password.values, schema);

    setPassword(password => ({
      ...password,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
    // eslint-disable-next-line
  }, [password.values]);

  function handleChange(event) {
    event.persist();

    setPassword(password => ({
      ...password,
      values: {
        ...password.values,
        [event.target.name]: event.target.value
      },
      touched: {
        ...password.touched,
        [event.target.name]: true
      }
    }));
  }

  function hasError(field) {
    return password.touched[field] && password.errors[field] ? true : false;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);

    try {
      const { data } = await api.put('users/update-password', {
        current_password: password.values.senhaAtual,
        password: password.values.novaSenha,
        password_confirmation: password.values.confirmarNovaSenha,
      });

      if (data.success) {
        toast.success(data.success.message)
        updateUserLogged({username: password.values.username})
        setPassword({
          isValid: false,
          values: {},
          touched: {},
          errors: {}
        })
      }
    } catch (error) {
      if (error.response) {
        toast.error('Falha ao atualizar, verifique os dados.')
      } else {
        toast.error('Error interno, por favor tente em instantes.')
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <Card
      {...rest}
      className={clsx(className)}
    >
      <form
        autoComplete="off"
        data-testid="form-update-profile"
        onSubmit={handleSubmit}
      >
        <CardHeader
          title="Alterar senha"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              <TextField
                error={hasError('senhaAtual')}
                fullWidth
                helperText={
                  hasError('senhaAtual') ? password.errors.senhaAtual[0] : null
                }
                inputProps={{ 'data-testid': 'input-password' }}
                label="Senha atual"
                name="senhaAtual"
                onChange={handleChange}
                type="password"
                value={password.values.senhaAtual || ''}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              <TextField
                error={hasError('novaSenha')}
                fullWidth
                helperText={
                  hasError('novaSenha') ? password.errors.novaSenha[0] : null
                }
                label="Nova senha"
                name="novaSenha"
                onChange={handleChange}
                type="password"
                value={password.values.novaSenha || ''}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              <TextField
                error={hasError('confirmarNovaSenha')}
                fullWidth
                helperText={
                  hasError('confirmarNovaSenha') ? password.errors.confirmarNovaSenha[0] : null
                }
                label="Confirmar nova senha"
                name="confirmarNovaSenha"
                onChange={handleChange}
                type="password"
                value={password.values.confirmarNovaSenha || ''}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            className={classes.buttonSubmit}
            color="primary"
            disableFocusRipple
            disabled={!password.isValid}
            type="submit"
            variant="contained"
          >
            {loading ? <CircularProgress
              color="#fff"
              size={24} /> : 'Salvar alterações'}
          </Button>
        </CardActions>
      </form>
    </Card>
  );
}

ChangePassword.propTypes = {
  className: PropTypes.string,
  password: PropTypes.object,
  setPassword: PropTypes.func
};
