import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
  CircularProgress
} from '@material-ui/core';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import { toast } from 'react-toastify';

import api from '../../../../services/api';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.success.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  }
}));

export default function TotalCustomers({ className, ...rest }) {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [totalCustomers, setTotalCustomers] = useState(0);

  useEffect(() => {
    loadCustomers();
  }, []);

  async function loadCustomers() {
    try {
      setLoading(true);

      const response = await api.get('customers');

      const numberOfCustomers = response.data.total;

      setTotalCustomers(numberOfCustomers);
    } catch (error) {
      toast.error(
        'Falha ao carregar alguns dados, por favor recarregue a página.'
      );
    } finally {
      setLoading(false);
    }
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Grid container justify="space-between">
              <Grid item>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                  variant="body2">
                  Total de clientes
                </Typography>
                <Typography variant="h3">{totalCustomers}</Typography>
              </Grid>
              <Grid item>
                <Avatar className={classes.avatar}>
                  <PeopleIcon className={classes.icon} />
                </Avatar>
              </Grid>
            </Grid>
          </>
        )}
      </CardContent>
    </Card>
  );
}

TotalCustomers.propTypes = {
  className: PropTypes.string
};
