import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import MaterialTable from 'material-table';
import { toast } from 'react-toastify';

import { UserToolbar } from '../components';
import UserCreate from '../UserCreate';
import ResetPassword from './ResetPassword';
import api from '../../../services/api';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
}));

export default function UserList() {
  const classes = useStyles();

  const [users, setUsers] = useState({
    columns: [
      { title: 'Nome', field: 'username', editable: 'never' },
      { title: 'E-mail', field: 'email', editable: 'never' },
      { title: 'Tipo', field: 'is_admin', lookup: { 0: 'Normal', 1: 'Administrador' }, },
    ],
    data: []
  });
  const [openForm, setOpenForm] = useState(false);
  const [openConfirmPasswordReset, setOpenConfirmPasswordReset] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});

  useEffect(() => {
    loadUsers();
    // eslint-disable-next-line
  }, [])

  async function loadUsers() {
    try {
      const { data } = await api.get('users');

      setUsers({...users, data})
    } catch (error) {
      toast.error('Falha ao carregar os dados.');
    }
  }

  async function resetPassword({id, username}) {
    await setSelectedUser({id, username})
    setOpenConfirmPasswordReset(true)
  }

  async function updateUser(user) {
    try {
      const { id, is_admin } = user;
      
      const { data } = await api.put(`type-user/${id}`, {
        is_admin: Number(is_admin)
      });

      if (data.success) {
        toast.success(data.success.message);
      }
    } catch (error) {
      if (error.response) {
        error.response.data.map(error => toast.error(error.message))
      } else {
        toast.error('Error interno, por favor tente em instantes.')
      }
    } finally {
      loadUsers();
    }
  }

  async function deleteUser(user) {
    try {
      const { data } = await api.delete(`user/${user.id}`)

      if (data.success) {
        toast.success(data.success.message);
        loadUsers();
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.error.message);
      } else {
        toast.error('Error interno, por favor tente em instantes.')
      }
    }
  }

  return (
    <div className={classes.root}>
      <UserToolbar
        setOpenForm={setOpenForm}
      />
      <div className={classes.content}>
        <MaterialTable
          actions={[
            {
              icon: 'restore',
              tooltip: 'Resetar senha',
              onClick: (_, rowData) => resetPassword(rowData)
            }
          ]}
          columns={users.columns}
          data={users.data}
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise(resolve => {
                setTimeout(() => {
                  resolve();
                  const data = [...users.data];
                  const userSelected = data[data.indexOf(oldData)] = newData;
                  updateUser(userSelected)
                }, 600);
              }),
            onRowDelete: oldData =>
              new Promise(resolve => {
                setTimeout(() => {
                  resolve();
                  const data = [...users.data];
                  const userSelected = data[data.indexOf(oldData)];
                  deleteUser(userSelected)
                }, 600);
              }),
          }}
          localization={{
            header: {
              actions: 'Ações'
            },
            body: {
              emptyDataSourceMessage: 'Não há registros a serem exibidos',
              deleteTooltip: 'Deletar usuário',
              editTooltip: 'Editar usuário',
              editRow: {
                saveTooltip: 'Confirmar',
                cancelTooltip: 'Cancelar',
                deleteText: 'Tem certeza de que deseja excluir este usuário?'
              }
            },
            toolbar: {
              searchPlaceholder: 'Buscar usuário',
              searchTooltip: 'Buscar'
            },
            pagination: {
              labelRowsSelect: 'linhas',
              labelDisplayedRows: '{from}-{to} de {count}',
              firstTooltip: 'Primeira página',
              lastTooltip: 'Última página',
              previousTooltip: 'Página anterior',
              nextTooltip: 'Próxima página'
            }
          }}
          title="Usuários"
        />
      </div>
      <UserCreate
        loadUsers={loadUsers}
        openForm={openForm}
        setOpenForm={setOpenForm}
      />
      <ResetPassword
        openConfirmPasswordReset={openConfirmPasswordReset}
        selectedUser={selectedUser}
        setOpenConfirmPasswordReset={setOpenConfirmPasswordReset}
        setSelectedUser={setSelectedUser}
      />
    </div>
  );
}
