import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import Route from './Route';

import {
  Dashboard as DashboardView,
  Products as ProductsView,
  Logs as LogsView,
  Customers as CustomersView,
  Constructions as ConstructionsView,
  Requesters as RequestersView,
  Payments as PaymentsView,
  Users as UsersView,
  Exits as ExitsView,
  Budgets as BudgetsView,
  Reports as ReportsView,
  EnableUser as EnableUserView,
  Account as AccountView,
  SignIn as SignInView,
  NotFound as NotFoundView
} from '../views';

export default function Routes() {
  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/dashboard" />
      <Route
        component={DashboardView}
        exact
        isPrivate
        path="/dashboard" />
      <Route
        component={UsersView}
        exact
        isPrivate
        path="/users" />
      <Route
        component={ProductsView}
        exact
        isPrivate
        path="/products" />
      <Route
        component={CustomersView}
        exact
        isPrivate
        path="/customers" />
      <Route
        component={ConstructionsView}
        exact
        isPrivate
        path="/constructions"
      />
      <Route
        component={RequestersView}
        exact
        isPrivate
        path="/requesters"
      />
      <Route
        component={PaymentsView}
        exact
        isPrivate
        path="/payments" />
      <Route
        component={LogsView}
        exact
        isPrivate
        path="/logs" />
      <Route
        component={ExitsView}
        exact
        isPrivate
        path="/exits" />
      <Route
        component={BudgetsView}
        exact
        isPrivate
        path="/budgets" />
      <Route
        component={ReportsView}
        exact
        isPrivate
        path="/reports" />
      <Route
        component={AccountView}
        exact
        isPrivate
        path="/account" />
      <Route
        component={EnableUserView}
        exact
        path="/enable-user" />
      <Route
        component={SignInView}
        exact
        path="/sign-in" />
      <Route
        component={NotFoundView}
        exact
        path="/not-found" />
      <Redirect to="/not-found" />
    </Switch>
  );
}
