import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import moment from 'moment';
import uuid from 'uuid/v1';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

import api from '../../../services/api';
import { formatPrice, reverseFormatPrice } from '../../../util/formatCurrency';

import AddProduct from './components/AddProduct';
import AddProductCustom from './components/AddProductCustom';
import 'moment/locale/pt-br';
import MomentUtils from '@date-io/moment';

const locale = moment.locale('pt-br');

const useStyles = makeStyles(theme => ({
  buttonAddProductCustom: {
    background: '#1e88e5',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#42a5f5',
      borderColor: '#0062cc'
    },
    flexGrow: 0.5
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  }
}));

export default function Form({
  openFormUpdate,
  setOpenFormUpdate,
  data,
  setData,
  loadData,
  products,
  selectedBudget,
  payments,
  setSelectedBudget
}) {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [continueBudget, setContinueBudget] = useState(false);
  const [date, setDate] = useState('');
  const [selectedProduct, setSelectedProduct] = useState({});
  const [selectedPayment, setSelectedPayment] = useState({});
  const [selectedDate, setSelectedDate] = useState();
  const [fields, setFields] = useState([]);
  const [fieldsCustom, setFieldsCustom] = useState([]);
  const [totalBudget, setTotalBudget] = useState('');
  const [totalDiscount, setTotalDiscount] = useState({
    discountPercentage: '',
    discountedValue: '',
    discountAmount: ''
  });

  useEffect(() => {
    // const totalBudgetConverted = reverseFormatPrice(totalBudget);
    // const { discountPercentage } = totalDiscount;
    // const calculatedValue = (totalBudgetConverted * discountPercentage) / 100;

    setData({
      ...data,
      values: {
        ...data.values,
        cliente: selectedBudget.customer && selectedBudget.customer.name
      }
    });

    setFields(
      (selectedBudget.products &&
        selectedBudget.products.map(item => ({
          ...item,
          priceFormatted: formatPrice(item.price)
        }))) ||
        []
    );

    setTotalDiscount({
      ...totalDiscount,
      discountPercentage:
        (selectedBudget.discount && selectedBudget.discount.percentage) || ''
      // discountedValue: formatPrice(totalBudgetConverted - calculatedValue)
    });

    setSelectedDate(selectedBudget.approval_date || new Date());

    setSelectedPayment({ ...selectedPayment, id: selectedBudget.payment_id });

    setDate(selectedBudget.approvalDateFormatted || 'Ainda não aprovado');
    // eslint-disable-next-line
  }, [selectedBudget]);

  useEffect(() => {
    function calculateTotalBudget() {
      const total = fields.concat(fieldsCustom);

      const calculatedValue = total.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.price * currentValue.pivot.quantity;
      }, 0);

      setTotalBudget(formatPrice(calculatedValue));
    }
    if (fields.length || fieldsCustom.length) {
      calculateTotalBudget();
    }
    // eslint-disable-next-line
  }, [fields, fieldsCustom]);

  useEffect(() => {
    function percentCalculation() {
      const totalBudgetConverted = reverseFormatPrice(totalBudget);
      const { discountPercentage } = totalDiscount;

      const calculatedValue = (totalBudgetConverted * discountPercentage) / 100;

      setTotalDiscount({
        ...totalDiscount,
        discountedValue: formatPrice(totalBudgetConverted - calculatedValue),
        discountAmount: calculatedValue
      });
    }
    percentCalculation();
    // eslint-disable-next-line
  }, [totalDiscount.discountPercentage, totalBudget]);

  async function resetState() {
    setDate('');
    setData({
      isValid: false,
      values: {},
      touched: {},
      errors: {}
    });
    setFields([]);
    setFieldsCustom([]);
    setContinueBudget(false);
    setSelectedPayment({});
    setSelectedProduct({});
    setSelectedBudget({});
    setTotalDiscount({
      discountPercentage: '',
      discountedValue: '',
      discountAmount: ''
    });
  }

  function handleClose() {
    resetState();
    setOpenFormUpdate(false);
  }

  async function handleProductSelected(productSelected) {
    if (productSelected === -1) {
      return;
    } else {
      const { id, price, name, priceFormatted } = JSON.parse(productSelected);

      setSelectedProduct({ id, price, name, priceFormatted, quantity: 1 });
    }
  }

  async function handlePaymentSelected(paymentSelected) {
    if (paymentSelected === -1) {
      return;
    } else {
      const { id, name } = JSON.parse(paymentSelected);

      setSelectedPayment({ id, name });
    }
  }

  function handleChange(event) {
    event.persist();

    setData(data => ({
      ...data,
      values: {
        ...data.values,
        [event.target.name]: event.target.value
      },
      touched: {
        ...data.touched,
        [event.target.name]: true
      }
    }));
  }

  function handleAddProduct() {
    let newArray = [...fields];
    const { id, price, name, quantity, priceFormatted } = selectedProduct;

    const checkExistenceValues = fields.findIndex(item => item.id === id);

    if (checkExistenceValues >= 0) {
      toast.info(
        'O produto já está adicionado, se desejar aumente a quantidade por favor.'
      );
    } else {
      newArray.push({
        id,
        name,
        price,
        pivot: {
          quantity
        },
        priceFormatted
      });
      setFields(newArray);
    }
  }

  function handleAddProductCustom() {
    const values = [...fieldsCustom];
    values.push({
      id: uuid(),
      name: '',
      price: 0,
      pivot: {
        quantity: 1
      }
    });
    setFieldsCustom(values);
  }

  function handleRemoveProduct(id) {
    const valuesFiltered = id => fields.filter(item => item.id !== id);
    const values = valuesFiltered(id);

    setFields(values);
  }

  function handleRemoveProductCustom(id) {
    const valuesFiltered = id => fieldsCustom.filter(item => item.id !== id);
    const values = valuesFiltered(id);
    setFieldsCustom(values);
  }

  function formatValueTotal(amount, discount) {
    const amountConverted = reverseFormatPrice(amount);
    const discountConverted = discount ? parseFloat(discount).toFixed(2) : 0;
    const total = Number(amountConverted) - discountConverted;

    return total;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    if (moment(selectedDate, 'DD/MM/YYYY', true).isValid()) {
      try {
        setLoading(true);

        const idBudget = selectedBudget.id;
        const idPayment = selectedPayment.id;
        const approvalDate = moment(selectedDate, 'DD/MM/YYYY').format(
          'YYYY-MM-DD'
        );
        const products = fields.map(item => ({
          id: item.id,
          quantity: item.pivot.quantity
        }));

        const discountPercentage = totalDiscount.discountPercentage;

        const discountAmount = parseFloat(totalDiscount.discountAmount).toFixed(
          2
        );

        const formatFieldsCustom = fieldsCustom.map(item => ({
          name: item.name,
          price: parseFloat(item.price).toFixed(2),
          quantity: item.pivot.quantity
        }));

        const dataSent = {
          payment_id: idPayment,
          status: idPayment ? 1 : 0,
          approval_date: idPayment ? approvalDate : null,
          amount: formatValueTotal(totalBudget, totalDiscount.discountAmount),
          products,
          custom_products: formatFieldsCustom,
          discount_percentage: discountPercentage,
          discount_amount: discountAmount
        };

        const response = await api.put(`/budgets/${idBudget}`, dataSent);

        if (response.data.success) {
          toast.success(response.data.success.message);
        }
      } catch (error) {
        if (error.response.data.error) {
          toast.error(error.response.data.error.message);
        } else if (error.response) {
          error.response.data.map(error => toast.error(error.message));
        } else {
          toast.error('Error interno, por favor tente em instantes.');
        }
      } finally {
        handleClose();
        setData({
          isValid: false,
          values: {},
          touched: {},
          errors: {}
        });
        setLoading(false);
        loadData();
      }
    } else {
      toast.error('Por favor, informe uma data válida');
    }
  }

  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
      onClose={handleClose}
      open={openFormUpdate}
      scroll="body">
      <DialogTitle id="form-dialog-title">Cadastro de orçamento</DialogTitle>
      <form data-testid="product-form" onSubmit={handleSubmit}>
        <DialogContent>
          <DialogContentText>
            Informe os seguintes dados para prosseguir.
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item lg={9} md={9} sm={9} xs={12}>
              <Typography>Data de aprovação: {date}</Typography>
            </Grid>
            <Grid item lg={3} md={3} sm={3} xs={12}>
              <Button
                className={classes.buttonAddProductCustom}
                color="primary"
                disableFocusRipple
                fullWidth
                onClick={() => setContinueBudget(!continueBudget)}
                size="medium"
                variant="contained">
                Continuar orçamento
              </Button>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid
                alignItems="center"
                container
                direction="row"
                justify="space-between"
                spacing={2}>
                <Grid item lg={7} md={7} sm={7} xs={12}>
                  <Typography>Total do orçamento: {totalBudget}</Typography>
                  <Typography>
                    Total do orçamento com desconto:{' '}
                    {totalDiscount.discountedValue}
                  </Typography>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    className={classes.textField}
                    disabled={selectedBudget.payment_id && !continueBudget}
                    fullWidth
                    id="payment"
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{ 'data-testid': 'input-products' }}
                    label="Forma de pagamento"
                    margin="dense"
                    name="payment"
                    onChange={event =>
                      handlePaymentSelected(event.target.value)
                    }
                    select
                    SelectProps={{ native: true }}
                    variant="outlined">
                    <option value={-1}>Selecionar</option>
                    {payments.map((option, idx) => (
                      <option
                        key={`${idx}_${option.id}`}
                        selected={selectedBudget.payment_id === option.id}
                        value={JSON.stringify(option)}>
                        {option.name}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={12}>
                  <MuiPickersUtilsProvider
                    libInstance={moment}
                    locale={locale}
                    utils={MomentUtils}>
                    <DatePicker
                      disabled={!!selectedBudget.approval_date}
                      disableFuture={false}
                      disablePast
                      error={false}
                      format="DD/MM/YYYY"
                      helperText={''}
                      inputVariant="outlined"
                      label="Data de aprovação"
                      onChange={date => setSelectedDate(date)}
                      openTo="date"
                      required
                      value={selectedDate}
                      views={['year', 'month', 'date']}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={12}>
                  <TextField
                    disabled={!continueBudget}
                    fullWidth
                    // eslint-disable-next-line
                    InputLabelProps={{
                      shrink: true
                    }}
                    // eslint-disable-next-line
                    inputProps={{ 'data-testid': 'input-discount' }}
                    // eslint-disable-next-line
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      )
                    }}
                    label="Desconto"
                    margin="dense"
                    onChange={event =>
                      setTotalDiscount({
                        ...totalDiscount,
                        discountPercentage: event.target.value
                      })
                    }
                    type="number"
                    value={totalDiscount.discountPercentage}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid>
            {continueBudget && (
              <>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    disabled
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{ 'data-testid': 'input-client' }}
                    label="Cliente"
                    margin="dense"
                    name="cliente"
                    onChange={handleChange}
                    required
                    value={data.values.cliente || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Grid
                    alignItems="center"
                    container
                    direction="row"
                    spacing={1}>
                    <Grid item lg={3} md={3} sm={3} xs={12}>
                      <TextField
                        className={classes.textField}
                        fullWidth
                        // eslint-disable-next-line
                        id="products"
                        InputLabelProps={{
                          shrink: true
                        }}
                        inputProps={{ 'data-testid': 'input-products' }}
                        label="Produtos"
                        margin="dense"
                        name="products"
                        onChange={event =>
                          handleProductSelected(event.target.value)
                        }
                        required
                        select
                        // eslint-disable-next-line
                        SelectProps={{ native: true }}
                        variant="outlined">
                        <option value={-1}>Selecionar</option>
                        {products.data.map((option, idx) => (
                          <option key={idx} value={JSON.stringify(option)}>
                            {option.name} - {option.priceFormatted}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                      <Button
                        color="primary"
                        disabled={
                          !selectedProduct.id || !!selectedBudget.approval_date
                        }
                        // eslint-disable-next-line
                        disableFocusRipple
                        fullWidth
                        onClick={handleAddProduct}
                        size="medium"
                        variant="contained">
                        <AddIcon className={classes.extendedIcon} />
                        Adicionar produto
                      </Button>
                    </Grid>
                    <Grid item lg={5} md={5} sm={5} xs={12}>
                      <Button
                        className={classes.buttonAddProductCustom}
                        color="primary"
                        disableFocusRipple
                        fullWidth
                        onClick={handleAddProductCustom}
                        size="medium"
                        variant="contained">
                        <AddIcon className={classes.extendedIcon} />
                        Adicionar produto personalizado
                      </Button>
                    </Grid>
                    {fields.map((field, idx) => {
                      return (
                        <AddProduct
                          field={field}
                          fields={fields}
                          handleRemoveProduct={handleRemoveProduct}
                          key={`${field}-${idx}`}
                          setFields={setFields}
                        />
                      );
                    })}
                    {fieldsCustom.map((field, idx) => {
                      return (
                        <AddProductCustom
                          field={field}
                          fieldsCustom={fieldsCustom}
                          handleRemoveProductCustom={handleRemoveProductCustom}
                          key={`${field}-${idx}`}
                          setFieldsCustom={setFieldsCustom}
                        />
                      );
                    })}
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button
            color="primary"
            disabled={loading || !!selectedBudget.approval_date}
            type="submit">
            {loading ? <CircularProgress /> : 'Atualizar'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

Form.propTypes = {
  data: PropTypes.shape(),
  loadData: PropTypes.func,
  openFormUpdate: PropTypes.bool,
  payments: PropTypes.array,
  products: PropTypes.shape(),
  selectedBudget: PropTypes.object,
  setData: PropTypes.func,
  setOpenFormUpdate: PropTypes.func,
  setSelectedBudget: PropTypes.func
};
