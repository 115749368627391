import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import { PDFViewer } from '@react-pdf/renderer';
import { toast } from 'react-toastify';

import TabPanel from '../TabPanel';
import PdfDocument from './PdfDocument';

import api from '../../../../services/api';

const useStyles = makeStyles(theme => ({
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
}));

export default function FirstTab({ theme, index, value }) {
  const classes = useStyles();

  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);

  async function handleGenerateReport() {
    try {
      const response = await api.get('/reports/debtors/customers');
      if (response.data.length === 0) {
        toast.info('Não existem dados para essa categoria')
      } else {
        setData({
          data: [...response.data],
          dateFormatted: moment(new Date()).format('DD/MM/YYYY')
        });
        setShow(true);
      }
    } catch (error) {
      toast.error('Falha ao gerar relatório, por favor tente em instantes');
      setShow(false);
    }
  }

  return (
    <TabPanel
      dir={theme}
      index={index}
      value={value}
    >
      <Button
        color="primary"
        disableFocusRipple
        onClick={handleGenerateReport}
        variant="contained"
      >
        Gerar relatório
        <PictureAsPdfIcon className={classes.rightIcon} />
      </Button>
      {show && <PDFViewer
        children={<PdfDocument data={data} />}
        height="700px"
        style={{ marginTop: 10 }}
        width="100%"
      />}
    </TabPanel>
  );
}

FirstTab.propTypes = {
  index: PropTypes.any.isRequired,
  theme: PropTypes.object,
  value: PropTypes.any.isRequired,
};
