import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import { AccountProfile, AccountDetails, ChangePassword } from './components';
import api from '../../services/api';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

export default function Account() {
  const classes = useStyles();

  const [user, setUser] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });
  const [password, setPassword] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });
  const [selectedDate, setSelectedDate] = useState(new Date());

  async function loadUser() {
    const { data } = await api.get('user');
    
    const userData = {
      email: data.email,
      nomeDeUsuário: data.username,
    }
    setUser(user => ({
      ...user,
      values: userData,
    }));
    setSelectedDate(data.birth_date)
  }

  useEffect(() => {
    loadUser();
  }, [])

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={4}
          md={6}
          xl={4}
          xs={12}
        >
          <AccountProfile user={user} />
        </Grid>
        <Grid
          item
          lg={8}
          md={6}
          xl={8}
          xs={12}
        >
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              xs={12}
            >
              <AccountDetails
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate} 
                setUser={setUser}
                user={user}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <ChangePassword
                password={password}
                setPassword={setPassword} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
