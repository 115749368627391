import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  details: {
    display: 'flex'
  },
}));

export default function AccountProfile({ className, user, ...rest }) {

  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(className)}
    >
      <CardContent>
        <div className={classes.details}>
          <div>
            <Typography
              gutterBottom
              variant="h3"
            >
              {user.values.email}
            </Typography>
            <Typography
              className={classes.dateText}
              color="textSecondary"
              variant="body1"
            >
              {moment().format('hh:mm A')}
            </Typography>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

AccountProfile.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object
};
