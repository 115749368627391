import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import validate from 'validate.js';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import 'moment/locale/pt-br';
import MomentUtils from '@date-io/moment';

import api from '../../../services/api';

import { reverseFormatPrice } from '../../../util/formatCurrency'

const locale = moment.locale('pt-br');

const schema = {
  nome: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  preço: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
};

export default function Form({ openForm, setOpenForm, data, setData, loadExits }) {
  
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    const errors = validate(data.values, schema);

    setData(data => ({
      ...data,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
    // eslint-disable-next-line
  }, [data.values]);

  function handleChange(event) {
    event.persist();

    setData(data => ({
      ...data,
      values: {
        ...data.values,
        [event.target.name]: event.target.value
      },
      touched: {
        ...data.touched,
        [event.target.name]: true
      }
    }));
  }
  
  function handleClose() {
    return setOpenForm(false);
  }

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setLoading(true);
      const price = reverseFormatPrice(data.values.preço);
      const { nome } = data.values;

      const response = await api.post('exits', {
        name: nome,
        date: moment(selectedDate).format('YYYY-MM-DD'),
        price,
      })
      
      if (response.data.success) {
        toast.success(response.data.success.message)
      }
    } catch (error) {
      error.response.data.map(error => toast.error(error.message))
    } finally {
      handleClose()
      setData({
        isValid: false,
        values: {},
        touched: {},
        errors: {}
      })
      setLoading(false)
      loadExits()
    }
  }

  function hasError(field) {
    return data.touched[field] && data.errors[field] ? true : false;
  }

  return (
    <div>
      <Dialog
        aria-labelledby="form-dialog-title"
        onClose={handleClose}
        open={openForm}
      >
        <DialogTitle id="form-dialog-title">Cadastro de Saída</DialogTitle>
        <form
          data-testid="user-form"
          onSubmit={handleSubmit}
        >
          <DialogContent>
            <DialogContentText>
            Informe os seguintes dados para prosseguir.
            </DialogContentText>
            <TextField
              autoFocus
              error={hasError('nome')}
              fullWidth
              helperText={
                hasError('nome') ? data.errors.nome[0] : null
              }
              inputProps={{ 'data-testid': 'input-name' }}
              label="Nome"
              margin="dense"
              name="nome"
              onChange={handleChange}
              type="text"
              value={data.values.nome || ''}              
            />
            <MuiPickersUtilsProvider
              libInstance={moment}
              locale={locale}
              utils={MomentUtils}>
              <DatePicker
                disableFuture
                format="DD/MM/YYYY"
                label="Data de nascimento"
                onChange={date => setSelectedDate(date)}
                openTo="year"
                required
                value={selectedDate}
                views={['year', 'month', 'date']}
              />
            </MuiPickersUtilsProvider>
            <TextField
              error={hasError('preço')}
              fullWidth
              helperText={
                hasError('preço') ? data.errors.preço[0] : null
              }
              inputProps={{ 'data-testid': 'input-price' }}
              // eslint-disable-next-line
              InputProps={{
                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
              }}
              label="Preço"
              margin="dense"
              name="preço"
              onChange={handleChange}
              value={data.values.preço || ''}
            />
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={handleClose}
            >
            Cancelar
            </Button>
            <Button
              color="primary"
              disabled={!data.isValid || loading}
              type="submit"
            >
              {loading ? <CircularProgress /> : 'Cadastrar'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

Form.propTypes = {
  data: PropTypes.shape(),
  loadExits: PropTypes.func,
  openForm: PropTypes.bool,
  setData: PropTypes.func,
  setOpenForm: PropTypes.func,
};
