import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import { BlobProvider } from '@react-pdf/renderer';

import ProductDetails from './components/ProductDetails';
import PdfDocument from './PdfDocument';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1)
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  iconSmall: {
    fontSize: 20
  }
}));

export default function Form({
  productData,
  openPreviewForm,
  setOpenPreviewForm,
  setProductData
}) {
  const classes = useStyles();

  function handleClose() {
    setOpenPreviewForm(false);
    setProductData({});
  }

  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
      onClose={handleClose}
      open={openPreviewForm || false}
      scroll="body">
      <DialogTitle id="form-dialog-title">Detalhes do orçamento</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Informe os seguintes dados para prosseguir.
        </DialogContentText>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography>
              Data de criação: {productData.dateFormatted}
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid
              alignItems="center"
              container
              direction="row"
              justify="space-between"
              spacing={2}>
              <Grid item lg={7} md={7} sm={7} xs={12}>
                <Typography>
                  Total do orçamento: {productData.amountOriginalFormatted}
                </Typography>
                {productData.totalDiscount && (
                  <Typography>
                    Total do orçamento com desconto:{' '}
                    {productData.amountFormatted}
                  </Typography>
                )}
              </Grid>
              <Grid item lg={3} md={3} sm={3} xs={12}>
                <TextField
                  disabled
                  fullWidth
                  // eslint-disable-next-line
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{ 'data-testid': 'input-discount' }}
                  label="Desconto"
                  margin="dense"
                  value={`${
                    productData.discount
                      ? productData.discount.percentage + '%'
                      : 'Sem desconto'
                  }`}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography>
                Status de aprovação: {productData.statusFormatted}
              </Typography>
            </Grid>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextField
              disabled
              fullWidth
              // eslint-disable-next-line
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{ 'data-testid': 'input-client' }}
              label="Cliente"
              margin="dense"
              value={productData.customer && productData.customer.name}
              variant="outlined"
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextField
              disabled
              fullWidth
              // eslint-disable-next-line
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{ 'data-testid': 'input-payment' }}
              label="Forma de pagamento"
              margin="dense"
              value={productData.payment && productData.payment.name}
              variant="outlined"
            />
          </Grid>
          {productData.requester && (
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <TextField
                disabled
                fullWidth
                // eslint-disable-next-line
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{ 'data-testid': 'input-payment' }}
                label="Solicitante"
                margin="dense"
                value={
                  productData.requester && productData.requester.name_requester
                }
                variant="outlined"
              />
            </Grid>
          )}
          {productData.construction && (
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <TextField
                disabled
                fullWidth
                // eslint-disable-next-line
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{ 'data-testid': 'input-payment' }}
                label="Obra"
                margin="dense"
                value={
                  productData.construction &&
                  productData.construction.name_construction
                }
                variant="outlined"
              />
            </Grid>
          )}
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography>Produtos</Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid alignItems="center" container direction="row" spacing={1}>
              {productData.products &&
                productData.products.map((field, idx) => {
                  return (
                    <ProductDetails field={field} key={`${field}-${idx}`} />
                  );
                })}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <BlobProvider document={<PdfDocument productData={productData} />}>
          {({ url, loading }) =>
            loading ? (
              <CircularProgress />
            ) : (
              <Button
                className={classes.button}
                color="primary"
                disableFocusRipple
                href={url}
                target="_blank"
                variant="contained">
                Imprimir
                <Icon className={classes.rightIcon}>print</Icon>
              </Button>
            )
          }
        </BlobProvider>
        <Button color="primary" disableFocusRipple onClick={handleClose}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

Form.propTypes = {
  openPreviewForm: PropTypes.bool,
  productData: PropTypes.object,
  setOpenPreviewForm: PropTypes.func,
  setProductData: PropTypes.func
};
