import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import validate from 'validate.js';

import api from '../../../services/api';

const schema = {
  nome: {
    presence: { allowEmpty: false, message: 'é obrigatório' }
  },
  email: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
    email: {
      message: 'não parece válido'
    },
    length: {
      maximum: 64
    }
  },
  telefone: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
    length: {
      minimum: 7,
      message: 'deve ter pelo menos 7 caracteres'
    }
  }
};

export default function Form({
  openForm,
  setOpenForm,
  data,
  setData,
  loadCustomers
}) {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = React.useState(false);

  useEffect(() => {
    const errors = validate(data.values, schema);

    setData(data => ({
      ...data,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
    // eslint-disable-next-line
  }, [data.values]);

  function handleChange(event) {
    event.persist();

    setData(data => ({
      ...data,
      values: {
        ...data.values,
        [event.target.name]: event.target.value
      },
      touched: {
        ...data.touched,
        [event.target.name]: true
      }
    }));
  }

  function handleChangeSwitch() {
    setValue(!value);
  }

  function hasError(field) {
    return data.touched[field] && data.errors[field] ? true : false;
  }

  const handleClose = () => {
    setOpenForm(false);
  };

  const handleSubmit = async e => {
    e.preventDefault();

    try {
      setLoading(true);

      const { nome, email, telefone } = data.values;

      const { data: responseData } = await api.post('customers', {
        name: nome,
        email,
        phone: telefone,
        company: value ? 1 : 0
      });

      if (responseData.success) {
        toast.success(responseData.success.message);
      }
    } catch (error) {
      if (error.response) {
        error.response.data.map(error => toast.error(error.message));
      } else {
        toast.error('Error interno, por favor tente em instantes.');
      }
    } finally {
      handleClose();
      setData({
        isValid: false,
        values: {},
        touched: {},
        errors: {}
      });
      setValue(false);
      setLoading(false);
      loadCustomers();
    }
  };

  return (
    <div>
      <Dialog
        aria-labelledby="form-dialog-title"
        onClose={handleClose}
        open={openForm}>
        <DialogTitle id="form-dialog-title">Cadastro de Cliente</DialogTitle>
        <form
          data-testid="customer-form"
          onSubmit={handleSubmit}>
          <DialogContent>
            <DialogContentText>
              Informe os seguintes dados para prosseguir.
            </DialogContentText>
            <TextField
              autoFocus
              error={hasError('nome')}
              fullWidth
              helperText={hasError('nome') ? data.errors.nome[0] : null}
              inputProps={{ 'data-testid': 'input-name' }}
              label="Nome"
              margin="dense"
              name="nome"
              onChange={handleChange}
              type="text"
              value={data.values.nome || ''}
            />
            <TextField
              error={hasError('email')}
              fullWidth
              helperText={hasError('email') ? data.errors.email[0] : null}
              inputProps={{ 'data-testid': 'input-email' }}
              label="E-mail"
              margin="dense"
              name="email"
              onChange={handleChange}
              type="email"
              value={data.values.email || ''}
            />
            <TextField
              error={hasError('telefone')}
              fullWidth
              helperText={hasError('telefone') ? data.errors.telefone[0] : null}
              inputProps={{ 'data-testid': 'input-phone' }}
              label="Telefone"
              margin="dense"
              name="telefone"
              onChange={handleChange}
              type="phone"
              value={data.values.telefone || ''}
            />
            <FormControl
              component="fieldset"
              onChange={handleChangeSwitch}>
              <FormControlLabel
                control={<Switch
                  color="primary"
                  value={value} />}
                label="O cliente é uma empresa ?"
                labelPlacement="start"
                style={{ marginLeft: 0, marginTop: 10 }}
                value="start"
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={handleClose}>
              Cancelar
            </Button>
            <Button
              color="primary"
              disabled={!data.isValid || loading}
              type="submit">
              {loading ? <CircularProgress /> : 'Cadastrar'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

Form.propTypes = {
  data: PropTypes.shape(),
  loadCustomers: PropTypes.func,
  openForm: PropTypes.bool,
  setData: PropTypes.func,
  setOpenForm: PropTypes.func
};
