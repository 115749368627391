import React from 'react';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Close, Search } from '@material-ui/icons';
import PropTypes from 'prop-types';

export default function SearchInput({ onClear, ...rest }) {
  return (
    <TextField
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search fontSize="small" />
          </InputAdornment>
        ),
        endAdornment: rest.value && (
          <InputAdornment position="end">
            <IconButton onClick={onClear}>
              <Close fontSize="small" />
            </IconButton>
          </InputAdornment>
        )
      }}
      {...rest}
    />
  );
}

SearchInput.propTypes = {
  onClear: PropTypes.func
};
