import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  CircularProgress
} from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { toast } from 'react-toastify';
import moment from 'moment';
import 'moment/locale/pt-br';
import MomentUtils from '@date-io/moment';

import api from '../../../../services/api';
import { updateUserLogged } from '../../../../services/auth';

const locale = moment.locale('pt-br');

const schema = {
  nomeDeUsuário: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
    length: {
      maximum: 64
    }
  },
};

const useStyles = makeStyles(() => ({
  buttonSubmit: {
    width: '100%'
  }
}));

export default function AccountDetails({
  className,
  user,
  setUser,
  selectedDate,
  setSelectedDate,
  ...rest
}) {

  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const errors = validate(user.values, schema);

    setUser(user => ({
      ...user,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  // eslint-disable-next-line
  }, [user.values]);

  function handleChange(event) {
    event.persist();

    setUser(user => ({
      ...user,
      values: {
        ...user.values,
        [event.target.name]: event.target.value
      },
      touched: {
        ...user.touched,
        [event.target.name]: true
      }
    }));
  }

  function hasError(field) {
    return user.touched[field] && user.errors[field] ? true : false;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);

    try {
      const { data } = await api.put('user', {
        username: user.values.nomeDeUsuário,
        birth_date: moment(selectedDate).format('YYYY-MM-DD')
      });

      if (data.success) {
        toast.success(data.success.message)
        updateUserLogged({username: user.values.nomeDeUsuário})
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.error.message);
      } else {
        toast.error('Error interno, por favor tente em instantes.')
      }
    }
    finally {
      setLoading(false);
    }
  }

  return (
    <Card
      {...rest}
      className={clsx(className)}
    >
      <form
        autoComplete="off"
        data-testid="form-update-profile"
        onSubmit={handleSubmit}
      >
        <CardHeader
          title="Perfil"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              <TextField
                error={hasError('nomeDeUsuário')}
                fullWidth
                helperText={
                  hasError('nomeDeUsuário') ? user.errors.nomeDeUsuário[0] : null
                }
                // eslint-disable-next-line
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{ 'data-testid': 'input-username' }}
                label="Nome de usuário"
                margin="dense"
                name="nomeDeUsuário"
                onChange={handleChange}
                // required
                value={user.values.nomeDeUsuário || ''}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              <MuiPickersUtilsProvider
                libInstance={moment}
                locale={locale}
                utils={MomentUtils}>
                <DatePicker
                  disableFuture
                  error={false}
                  format="DD/MM/YYYY"
                  helperText={''}
                  inputVariant="outlined"
                  label="Data de nascimento"
                  onChange={date => setSelectedDate(date)}
                  openTo="year"
                  required
                  value={selectedDate || ''}
                  views={['year', 'month', 'date']}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            className={classes.buttonSubmit}
            color="primary"
            disableFocusRipple
            disabled={!user.isValid}
            type="submit"
            variant="contained"
          >
            {loading ? <CircularProgress
              color="#fff"
              size={24} /> : 'Salvar alterações'}
          </Button>
        </CardActions>
      </form>
    </Card>
  );
}

AccountDetails.propTypes = {
  className: PropTypes.string,
  selectedDate: PropTypes.string,
  setSelectedDate: PropTypes.func,
  setUser: PropTypes.func,
  user: PropTypes.object
};
