import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import MaterialTable from 'material-table';
import { toast } from 'react-toastify';

import { CustomerToolbar } from '../components';
import CustomerCreate from '../CustomerCreate';
import api from '../../../services/api';
import useDebounce from 'hooks/useDebounce';
import { Box, Typography } from '@material-ui/core';
import SearchInput from 'components/SearchInput';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
    background: '#fff'
  },
  table: {
    boxShadow: 'none',
    background: '#fff'
  }
}));

export default function CustomerList() {
  const classes = useStyles();

  const [customers, setCustomers] = useState({
    columns: [
      { title: 'Nome', field: 'name' },
      { title: 'E-mail', field: 'email' },
      { title: 'Telefone', field: 'phone' },
      { title: 'Companhia', field: 'company', lookup: { 0: 'Não', 1: 'Sim' } }
    ],
    data: []
  });
  const [openForm, setOpenForm] = useState(false);
  const [query, setQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
    total: 0,
    lastPage: 0
  });

  const debouncedSearch = useDebounce(handleSearchChange, 800);

  useEffect(() => {
    loadCustomers();
    // eslint-disable-next-line
  }, []);

  function handleSearchChange(searchTerm) {
    setQuery(searchTerm);
    loadCustomers(1, pagination.perPage, searchTerm);
  }

  async function loadCustomers(
    page = pagination.page,
    perPage = pagination.perPage,
    searchTerm = query
  ) {
    try {
      setIsLoading(true);

      const endpoint = searchTerm
        ? `customers/search/${searchTerm}`
        : 'customers';

      const { data } = await api.get(endpoint, {
        params: { page, limit: perPage }
      });

      setPagination({
        page: data.page,
        perPage: data.perPage,
        total: data.total,
        lastPage: data.lastPage
      });

      setCustomers(prevState => ({ ...prevState, data: data.data }));
    } catch (error) {
      toast.error('Falha ao carregar os dados.');
    } finally {
      setIsLoading(false);
    }
  }

  async function updateCustomer(customer) {
    try {
      const { data } = await api.put(`customers/${customer.id}`, {
        name: customer.name,
        email: customer.email,
        phone: customer.phone,
        company: customer.company
      });

      if (data.success) {
        toast.success(data.success.message);
      }
    } catch (error) {
      if (error.response) {
        if (Array.isArray(error.response.data)) {
          error.response.data.map(error => toast.error(error.message));
        } else {
          toast.error(error.response.data.error.message);
        }
      } else {
        toast.error('Error interno, por favor tente em instantes.');
      }
    } finally {
      loadCustomers();
    }
  }

  async function deleteCustomer(customer) {
    try {
      const { data } = await api.delete(`customers/${customer.id}`);

      if (data.success) {
        toast.success(data.success.message);
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.error.message);
      } else {
        toast.error('Error interno, por favor tente em instantes.');
      }
    } finally {
      loadCustomers();
    }
  }

  function handleClearSearch() {
    setQuery('');
    loadCustomers(1, pagination.perPage, '');
  }

  return (
    <div className={classes.root}>
      <CustomerToolbar setOpenForm={setOpenForm} />
      <div className={classes.content}>
        <Box borderRadius={4} className={classes.toolbar}>
          <Typography variant="h6">Clientes</Typography>
          <SearchInput
            onChange={e => {
              setQuery(e.target.value);
              debouncedSearch(e.target.value);
            }}
            onClear={handleClearSearch}
            placeholder="Buscar cliente"
            value={query}
          />
        </Box>
        <MaterialTable
          columns={customers.columns}
          components={{
            Container: props => <div {...props} className={classes.table} />
          }}
          data={customers.data}
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise(resolve => {
                setTimeout(() => {
                  resolve();
                  const data = [...customers.data];
                  const customerSelected = (data[
                    data.indexOf(oldData)
                  ] = newData);
                  updateCustomer(customerSelected);
                }, 600);
              }),
            onRowDelete: oldData =>
              new Promise(resolve => {
                setTimeout(() => {
                  resolve();
                  const data = [...customers.data];
                  const customerSelected = data[data.indexOf(oldData)];
                  deleteCustomer(customerSelected);
                }, 600);
              })
          }}
          isLoading={isLoading}
          localization={{
            header: {
              actions: 'Ações'
            },
            body: {
              emptyDataSourceMessage: 'Não há registros a serem exibidos',
              deleteTooltip: 'Deletar cliente',
              editTooltip: 'Editar cliente',
              editRow: {
                saveTooltip: 'Confirmar',
                cancelTooltip: 'Cancelar',
                deleteText: 'Tem certeza de que deseja excluir este cliente?'
              }
            },
            toolbar: {
              searchPlaceholder: 'Buscar cliente',
              searchTooltip: 'Buscar'
            },
            pagination: {
              labelRowsSelect: 'linhas',
              labelDisplayedRows: '{from}-{to} de {count}',
              firstTooltip: 'Primeira página',
              lastTooltip: 'Última página',
              previousTooltip: 'Página anterior',
              nextTooltip: 'Próxima página'
            }
          }}
          onChangePage={newPage => {
            setPagination(prev => ({ ...prev, page: newPage + 1 }));
            loadCustomers(newPage + 1, pagination.perPage, query);
          }}
          onChangeRowsPerPage={newPerPage => {
            setPagination(prev => ({ ...prev, perPage: newPerPage, page: 1 }));
            loadCustomers(1, newPerPage, query);
          }}
          options={{
            pageSize: pagination.perPage,
            search: false,
            toolbar: false,
            pageSizeOptions: [5, 10, 20]
          }}
          page={pagination.page - 1}
          title="Clientes"
          totalCount={pagination.total}
        />
      </div>
      <CustomerCreate
        loadCustomers={loadCustomers}
        openForm={openForm}
        setOpenForm={setOpenForm}
      />
    </div>
  );
}
