import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/styles';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import moment from 'moment';
import { PDFViewer } from '@react-pdf/renderer';
import 'moment/locale/pt-br';
import { toast } from 'react-toastify';
import MomentUtils from '@date-io/moment';
import 'moment/locale/pt-br';

import TabPanel from '../TabPanel';
import PdfDocument from './PdfDocument';
import Autocomplete from './Autocomplete';

import api from '../../../../services/api';
import { formatPrice } from '../../../../util/formatCurrency';

const locale = moment.locale('pt-br');

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 20
  },
  dateContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20
  },
  buttonMonth: {
    marginLeft: 10
  },
  button: {
    margin: theme.spacing(1)
  }
}));

export default function OctaveTab({ theme, index, value: valueIndex }) {
  const classes = useStyles();

  const [show, setShow] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [data, setData] = useState([]);
  const [value, setValue] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());

  // useEffect(() => {
  //   value && handleGenerateReport();
  // }, [value]);

  useEffect(() => {
    async function fetchCustomers() {
      try {
        const response = await api.get('customers/all');
        const data = response.data.map(customer => ({
          value: customer,
          label: customer.name
        }));
        setCustomers(data);
      } catch (error) {
        toast.error(
          'Falha ao carregar os dados, por favor tente em instantes.'
        );
      }
    }

    fetchCustomers();
  }, []);

  const handleMenuOpen = React.useCallback(e => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  }, []);

  async function handleGenerateReport() {
    if (!value) {
      return toast.info('Port favor, selecione um cliente');
    }

    try {
      const { id } = value.value;

      const response = await api.get(`reports/pendencies/${id}`);

      if (response.data.error) {
        toast.info(response.data.error.message);
        setShow(false);
      } else {
        const data = {
          data: response.data.budgets.map(budget => ({
            ...budget,
            formattedCreationDate: moment(budget.creation_date).format(
              'DD/MM/YYYY'
            ),
            formattedApprovalStatus: budget.approval_date
              ? moment(budget.approval_date).format('DD/MM/YYYY')
              : 'Pendente',
            amountFormatted: formatPrice(budget.amount)
          })),
          amountTotal: formatPrice(response.data.total),
          dateFormatted: moment(new Date()).format('DD/MM/YYYY')
        };
        setData(data);
        setShow(true);
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.error.message);
      } else {
        toast.error('Error interno, por favor tente em instantes.');
      }
      setShow(false);
    }
  }

  async function handleGenerateReportByYear() {
    if (!value) {
      return toast.info('Port favor, selecione um cliente');
    }

    try {
      const { id } = value.value;
      let year = moment(selectedDate).format('YYYY');

      const response = await api.get(`reports/pendencies/${id}/year/${year}`);

      if (response.data.error) {
        toast.info(response.data.error.message);
        setShow(false);
      } else {
        const data = {
          data: response.data.budgets.map(budget => ({
            ...budget,
            formattedCreationDate: moment(budget.creation_date).format(
              'DD/MM/YYYY'
            ),
            formattedApprovalStatus: budget.approval_date
              ? moment(budget.approval_date).format('DD/MM/YYYY')
              : 'Pendente',
            amountFormatted: formatPrice(budget.amount)
          })),
          amountTotal: formatPrice(response.data.total),
          dateFormatted: moment(new Date()).format('DD/MM/YYYY')
        };
        setData(data);
        setShow(true);
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.error.message);
      } else {
        toast.error('Error interno, por favor tente em instantes.');
      }
      setShow(false);
    }
  }

  async function handleGenerateReportByMonthAndYear() {
    if (!value) {
      return toast.info('Port favor, selecione um cliente');
    }

    try {
      const { id } = value.value;
      let year = moment(selectedDate).format('YYYY');
      let month = moment(selectedDate).format('MM');

      const response = await api.get(
        `reports/pendencies/${id}/year/${year}/month/${month}`
      );

      if (response.data.error) {
        toast.info(response.data.error.message);
        setShow(false);
      } else {
        const data = {
          data: response.data.budgets.map(budget => ({
            ...budget,
            formattedCreationDate: moment(budget.creation_date).format(
              'DD/MM/YYYY'
            ),
            formattedApprovalStatus: budget.approval_date
              ? moment(budget.approval_date).format('DD/MM/YYYY')
              : 'Pendente',
            amountFormatted: formatPrice(budget.amount)
          })),
          amountTotal: formatPrice(response.data.total),
          dateFormatted: moment(new Date()).format('DD/MM/YYYY')
        };
        setData(data);
        setShow(true);
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.error.message);
      } else {
        toast.error('Error interno, por favor tente em instantes.');
      }
      setShow(false);
    }
  }

  return (
    <TabPanel dir={theme} index={index} value={valueIndex}>
      <Autocomplete customers={customers} setValue={setValue} />

      <div className={classes.root}>
        <Button
          color="primary"
          disableFocusRipple
          onClick={handleGenerateReport}
          variant="contained">
          relatório geral
          <PictureAsPdfIcon className={classes.rightIcon} />
        </Button>

        <div className={classes.dateContainer}>
          <MuiPickersUtilsProvider
            libInstance={moment}
            locale={locale}
            utils={MomentUtils}>
            <DatePicker
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="Select locale"
                    aria-owns={anchorEl ? 'locale-menu' : undefined}
                    onClick={handleMenuOpen}
                  />
                )
              }}
              inputVariant="outlined"
              onChange={date => setSelectedDate(date)}
              value={selectedDate}
              variant="dialog"
              views={['year', 'month']}
            />
          </MuiPickersUtilsProvider>
          <Button
            className={classes.buttonMonth}
            color="primary"
            disableFocusRipple
            onClick={handleGenerateReportByMonthAndYear}
            variant="contained">
            gerar relatório
            <PictureAsPdfIcon className={classes.rightIcon} />
          </Button>
        </div>

        <div className={classes.dateContainer}>
          <MuiPickersUtilsProvider
            libInstance={moment}
            locale={locale}
            utils={MomentUtils}>
            <DatePicker
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="Select locale"
                    aria-owns={anchorEl ? 'locale-menu' : undefined}
                    onClick={handleMenuOpen}
                  />
                )
              }}
              inputVariant="outlined"
              onChange={date => setSelectedDate(date)}
              value={selectedDate}
              variant="dialog"
              views={['year']}
            />
          </MuiPickersUtilsProvider>
          <Button
            className={classes.buttonMonth}
            color="primary"
            disableFocusRipple
            onClick={handleGenerateReportByYear}
            variant="contained">
            gerar relatório
            <PictureAsPdfIcon className={classes.rightIcon} />
          </Button>
        </div>
      </div>

      {show && (
        <PDFViewer
          children={<PdfDocument data={data} />}
          height="700px"
          style={{ marginTop: 10 }}
          width="100%"
        />
      )}
    </TabPanel>
  );
}

OctaveTab.propTypes = {
  index: PropTypes.any.isRequired,
  theme: PropTypes.object,
  value: PropTypes.any.isRequired
};
