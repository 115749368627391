import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import FirstTab from './FirstTab';
import SecondTab from './SecondTab';
import ThirdTab from './ThirdTab';
import FourthTab from './FourthTab';
import FifthTab from './FifthTab';
import SixthTab from './SixthTab';
import SeventhTab from './SeventhTab';
import OctaveTab from './OctaveTab';
import NinthTab from './NinthTab';

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  };
}

export default function MainTab() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    return setValue(newValue);
  }

  function handleChangeIndex(index) {
    return setValue(index);
  }

  return (
    <>
      <AppBar color="default" position="static">
        <Tabs
          indicatorColor="primary"
          onChange={handleChange}
          textColor="primary"
          value={value}
          variant="scrollable">
          <Tab
            disableFocusRipple
            label="Clientes com orçamento pendente"
            {...a11yProps(0)}
          />
          <Tab
            disableFocusRipple
            label="Pendências por cliente"
            {...a11yProps(1)}
          />
          <Tab
            disableFocusRipple
            label="Orçamentos por cliente"
            {...a11yProps(2)}
          />
          <Tab
            disableFocusRipple
            label="Orçamentos pendentes"
            {...a11yProps(3)}
          />
          <Tab disableFocusRipple label="Obras por cliente" {...a11yProps(4)} />
          <Tab disableFocusRipple label="Saídas" {...a11yProps(5)} />
          <Tab disableFocusRipple label="Entradas" {...a11yProps(6)} />
          <Tab disableFocusRipple label="Balanço" {...a11yProps(7)} />
          <Tab
            disableFocusRipple
            label="Total por formas de pagamento"
            {...a11yProps(8)}
          />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}>
        <FirstTab dir={theme.direction} index={0} value={value} />
        <OctaveTab dir={theme.direction} index={1} value={value} />
        <SecondTab dir={theme.direction} index={2} value={value} />
        <ThirdTab dir={theme.direction} index={3} value={value} />
        <NinthTab dir={theme.direction} index={4} value={value} />
        <FourthTab dir={theme.direction} index={5} value={value} />
        <FifthTab dir={theme.direction} index={6} value={value} />
        <SixthTab dir={theme.direction} index={7} value={value} />
        <SeventhTab dir={theme.direction} index={8} value={value} />
      </SwipeableViews>
    </>
  );
}
