import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import validate from 'validate.js';

import api from '../../../services/api';

const schema = {
  nome: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
};

export default function Form({ openForm, setOpenForm, data, setData, loadPayments }) {
  
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const errors = validate(data.values, schema);

    setData(data => ({
      ...data,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
    // eslint-disable-next-line
  }, [data.values]);

  function handleChange(event) {
    event.persist();

    setData(data => ({
      ...data,
      values: {
        ...data.values,
        [event.target.name]: event.target.value
      },
      touched: {
        ...data.touched,
        [event.target.name]: true
      }
    }));
  }

  function hasError(field) {
    return data.touched[field] && data.errors[field] ? true : false;
  }
  
  function handleClose() {
    return setOpenForm(false);
  }

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setLoading(true);

      const { nome } = data.values;

      const { data: responseData } = await api.post('payments', {
        name: nome,
      })

      if (responseData.success) {
        toast.success(responseData.success.message)
      }
    } catch (error) {
      if (error.response) {
        error.response.data.map(error => toast.error(error.message))
      } else {
        toast.error('Error interno, por favor tente em instantes.')
      }
    } finally {
      handleClose()
      setData({
        isValid: false,
        values: {},
        touched: {},
        errors: {}
      })
      setLoading(false)
      loadPayments()
    }
  }

  return (
    <div>
      <Dialog
        aria-labelledby="form-dialog-title"
        onClose={handleClose}
        open={openForm}
      >
        <DialogTitle id="form-dialog-title">Cadastro de forma de pagamento</DialogTitle>
        <form
          data-testid="payment-form"
          onSubmit={handleSubmit}
        >
          <DialogContent>
            <DialogContentText>
            Informe os seguintes dados para prosseguir.
            </DialogContentText>
            <TextField
              autoFocus
              error={hasError('nome')}
              fullWidth
              helperText={
                hasError('nome') ? data.errors.nome[0] : null
              }
              inputProps={{ 'data-testid': 'input-name' }}
              label="Nome"
              margin="dense"
              name="nome"
              onChange={handleChange}
              type="text"
              value={data.values.nome || ''}
            />
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={handleClose}
            >
            Cancelar
            </Button>
            <Button
              color="primary"
              disabled={!data.isValid || loading}
              type="submit"
            >
              {loading ? <CircularProgress /> : 'Cadastrar'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

Form.propTypes = {
  data: PropTypes.shape(),
  loadPayments: PropTypes.func,
  openForm: PropTypes.bool,
  setData: PropTypes.func,
  setOpenForm: PropTypes.func,
};
