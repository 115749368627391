import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import Form from './Form';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
}));

export default function RequesterCreate({
  openForm,
  setOpenForm,
  loadRequesters,
  customers
}) {
  const classes = useStyles();

  const [data, setData] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid
          container
          spacing={3}>
          <Form
            customers={customers}
            data={data}
            loadRequesters={loadRequesters}
            openForm={openForm}
            setData={setData}
            setOpenForm={setOpenForm}
          />
        </Grid>
      </div>
    </div>
  );
}

RequesterCreate.propTypes = {
  customers: PropTypes.array,
  loadRequesters: PropTypes.func,
  openForm: PropTypes.bool,
  setOpenForm: PropTypes.func
};
