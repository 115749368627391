import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  }
}));

export default function ConstructionsToolbar({
  className,
  setOpenForm,
  ...rest
}) {
  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <span className={classes.spacer} />
        <Button
          color="primary"
          data-testid="button-add-construction"
          onClick={() => setOpenForm(true)}
          variant="contained">
          <AddIcon className={classes.rightIcon} />
          Adicionar obra
        </Button>
      </div>
    </div>
  );
}

ConstructionsToolbar.propTypes = {
  className: PropTypes.string,
  setOpenForm: PropTypes.func
};
