import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/styles';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import moment from 'moment';
import { PDFViewer } from '@react-pdf/renderer';
import 'moment/locale/pt-br';
import { toast } from 'react-toastify';
import MomentUtils from '@date-io/moment';
import 'moment/locale/pt-br';

import TabPanel from '../TabPanel';
import PdfDocument from './PdfDocument';
import Autocomplete from './Autocomplete';

import api from '../../../../services/api';
import { formatPrice } from '../../../../util/formatCurrency';
import { Grid } from '@material-ui/core';

const locale = moment.locale('pt-br');

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 20
  },
  dateContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20
  },
  buttonMonth: {
    marginLeft: 10
  },
  button: {
    margin: theme.spacing(1)
  }
}));

export default function NinthTab({ theme, index, value: valueIndex }) {
  const classes = useStyles();

  const [show, setShow] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [constructions, setConstructions] = useState([]);
  const [data, setData] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState({
    value: null,
    label: null
  });
  const [selectedConstruction, setSelectedConstruction] = useState({
    value: null,
    label: null
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [value, setValue] = React.useState('');

  const handleChange = event => {
    setValue(event.target.value);
  };

  // useEffect(() => {
  //   value && handleGenerateReport();
  // }, [value]);

  useEffect(() => {
    if (
      selectedCustomer.value &&
      !selectedCustomer.value.constructions.length
    ) {
      setConstructions([]);
      toast.info('O cliente selecionado não possui obras');
    } else {
      const data =
        selectedCustomer.value &&
        selectedCustomer.value.constructions.map(item => ({
          value: item,
          label: item.name_construction
        }));

      setConstructions(data);
    }
  }, [selectedCustomer]);

  useEffect(() => {
    async function fetchCustomers() {
      try {
        const response = await api.get('reports/constructionsbycustomers');
        const data = response.data.map(customer => ({
          value: customer,
          label: customer.name
        }));
        setCustomers(data);
      } catch (error) {
        toast.error(
          'Falha ao carregar os dados, por favor tente em instantes.'
        );
      }
    }

    fetchCustomers();
  }, []);

  const handleMenuOpen = React.useCallback(e => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  }, []);

  async function handleGenerateReport() {
    if (!selectedCustomer && !selectedConstruction) {
      return toast.info('Por favor, selecione um cliente e uma obra');
    }

    try {
      const { id } = selectedCustomer.value;
      const { id: idConstruction } = selectedConstruction.value;
      let year = moment(selectedDate).format('YYYY');
      let month = moment(selectedDate).format('MM');

      if (!value) {
        return toast.info('Por favor, selecione um filtro');
      }

      const mappingRequest = {
        todos: `reports/full/customers/${id}/constructions/${idConstruction}/year/${year}/month/${month}`,
        aprovados: `reports/approved/customers/${id}/constructions/${idConstruction}/year/${year}/month/${month}`,
        pendentes: `reports/unapproved/customers/${id}/constructions/${idConstruction}/year/${year}/month/${month}`
      };

      const response = await api.get(mappingRequest[value]);

      if (response.data.error) {
        toast.info(response.data.error.message);
        setShow(false);
      } else {
        const data = {
          data: response.data.budgets.map(budget => ({
            ...budget,
            formattedCreationDate: moment(budget.creation_date).format(
              'DD/MM/YYYY'
            ),
            formattedApprovalStatus: budget.approval_date
              ? moment(budget.approval_date).format('DD/MM/YYYY')
              : 'Pendente',
            amountFormatted: formatPrice(budget.amount)
          })),
          amountTotal: formatPrice(response.data.total),
          dateFormatted: moment(new Date()).format('DD/MM/YYYY')
        };
        setData(data);
        setShow(true);
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.error.message);
      } else {
        toast.error('Error interno, por favor tente em instantes.');
      }
      setShow(false);
    }
  }

  return (
    <TabPanel dir={theme} index={index} value={valueIndex}>
      <Grid container spacing={2}>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Autocomplete
            options={customers}
            placeholder="Selecione o cliente"
            setValue={setSelectedCustomer}
          />
        </Grid>
        {constructions && constructions.length > 0 && (
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Autocomplete
              options={constructions}
              placeholder="Selecione a obra"
              setValue={setSelectedConstruction}
            />
          </Grid>
        )}
      </Grid>

      {!!(
        selectedCustomer.value &&
        selectedConstruction &&
        selectedConstruction.value
      ) && (
        <Grid alignItems={'center'} container>
          <Grid item lg={4} md={4} sm={4} xs={12}>
            <MuiPickersUtilsProvider
              libInstance={moment}
              locale={locale}
              utils={MomentUtils}>
              <DatePicker
                InputProps={{
                  endAdornment: (
                    <IconButton
                      aria-label="Select locale"
                      aria-owns={anchorEl ? 'locale-menu' : undefined}
                      onClick={handleMenuOpen}
                    />
                  )
                }}
                inputVariant="outlined"
                onChange={date => setSelectedDate(date)}
                value={selectedDate}
                variant="dialog"
                views={['year', 'month']}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={12}>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="gender"
                name="gender1"
                onChange={handleChange}
                value={value}>
                <FormControlLabel
                  control={<Radio />}
                  label="Todos"
                  value="todos"
                />
                <FormControlLabel
                  control={<Radio />}
                  label="Aprovados"
                  value="aprovados"
                />
                <FormControlLabel
                  control={<Radio />}
                  label="Pendentes"
                  value="pendentes"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={12}>
            <Button
              className={classes.buttonMonth}
              color="primary"
              disableFocusRipple
              onClick={handleGenerateReport}
              variant="contained">
              gerar relatório
              <PictureAsPdfIcon className={classes.rightIcon} />
            </Button>
          </Grid>
        </Grid>
        // <Button
        //   color="primary"
        //   disableFocusRipple
        //   onClick={handleGenerateReport}
        //   variant="contained">
        //   Todos os relatórios
        //   <PictureAsPdfIcon className={classes.rightIcon} />
        // </Button>
      )}

      {/* <div className={classes.dateContainer}>
        <MuiPickersUtilsProvider
          libInstance={moment}
          locale={locale}
          utils={MomentUtils}>
          <DatePicker
            InputProps={{
              endAdornment: (
                <IconButton
                  aria-label="Select locale"
                  aria-owns={anchorEl ? 'locale-menu' : undefined}
                  onClick={handleMenuOpen}
                />
              )
            }}
            inputVariant="outlined"
            onChange={date => setSelectedDate(date)}
            value={selectedDate}
            variant="dialog"
            views={['year', 'month']}
          />
        </MuiPickersUtilsProvider>
        <Button
          className={classes.buttonMonth}
          color="primary"
          disableFocusRipple
          onClick={handleGenerateReportByMonthAndYear}
          variant="contained">
          gerar relatório
          <PictureAsPdfIcon className={classes.rightIcon} />
        </Button>
      </div>

      <div className={classes.dateContainer}>
        <MuiPickersUtilsProvider
          libInstance={moment}
          locale={locale}
          utils={MomentUtils}>
          <DatePicker
            InputProps={{
              endAdornment: (
                <IconButton
                  aria-label="Select locale"
                  aria-owns={anchorEl ? 'locale-menu' : undefined}
                  onClick={handleMenuOpen}
                />
              )
            }}
            inputVariant="outlined"
            onChange={date => setSelectedDate(date)}
            value={selectedDate}
            variant="dialog"
            views={['year']}
          />
        </MuiPickersUtilsProvider>
        <Button
          className={classes.buttonMonth}
          color="primary"
          disableFocusRipple
          onClick={handleGenerateReportByYear}
          variant="contained">
          gerar relatório
          <PictureAsPdfIcon className={classes.rightIcon} />
        </Button>
      </div> */}

      {show && (
        <PDFViewer
          children={<PdfDocument data={data} />}
          height="700px"
          style={{ marginTop: 10 }}
          width="100%"
        />
      )}
    </TabPanel>
  );
}

NinthTab.propTypes = {
  index: PropTypes.any.isRequired,
  theme: PropTypes.object,
  value: PropTypes.any.isRequired
};
