import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import validate from 'validate.js';
import moment from 'moment';
import 'moment/locale/pt-br';
import MomentUtils from '@date-io/moment';

import api from '../../../services/api';

const locale = moment.locale('pt-br');

const schema = {
  nomeDeUsuário: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  email: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
    length: {
      maximum: 64
    }
  },
};

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 201,
  },
}));

export default function Form({ openForm, setOpenForm, data, setData, loadUsers }) {
  
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    const errors = validate(data.values, schema);

    setData(data => ({
      ...data,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
    // eslint-disable-next-line
  }, [data.values]);

  function handleChange(event) {
    event.persist();

    setData(data => ({
      ...data,
      values: {
        ...data.values,
        [event.target.name]: event.target.value
      },
      touched: {
        ...data.touched,
        [event.target.name]: true
      }
    }));
  }
  
  function handleClose() {
    return setOpenForm(false);
  }

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setLoading(true);

      const { nomeDeUsuário, email, nivel } = data.values;

      const emailFiltered = email.split('@')[0]
      const emailFormatted = emailFiltered.concat('@newplot.com.br')

      const { data: responseData } = await api.post('users', {
        username: nomeDeUsuário,
        email: emailFormatted,
        is_admin: nivel,
        birth_date: moment(selectedDate).format('YYYY-MM-DD')
      })
      
      if (responseData.success) {
        toast.success(responseData.success.message)
      }
    } catch (error) {
      if (error.response) {
        error.response.data.map(error => toast.error(error.message))
      } else {
        toast.error('Error interno, por favor tente em instantes.')
      }
    } finally {
      handleClose()
      setData({
        isValid: false,
        values: {},
        touched: {},
        errors: {}
      })
      setLoading(false)
      loadUsers()
    }
  }

  function hasError(field) {
    return data.touched[field] && data.errors[field] ? true : false;
  }

  return (
    <div>
      <Dialog
        aria-labelledby="form-dialog-title"
        onClose={handleClose}
        open={openForm}
      >
        <DialogTitle id="form-dialog-title">Cadastro de Usuário</DialogTitle>
        <form
          data-testid="user-form"
          onSubmit={handleSubmit}
        >
          <DialogContent>
            <DialogContentText>
            Informe os seguintes dados para prosseguir.
            </DialogContentText>
            <Grid
              container
              spacing={1}
            >
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}>
                <TextField
                  autoFocus
                  error={hasError('nomeDeUsuário')}
                  fullWidth
                  helperText={
                    hasError('nomeDeUsuário') ? data.errors.nomeDeUsuário[0] : null
                  }
                  inputProps={{ 'data-testid': 'input-username' }}
                  label="Nome de usuário"
                  margin="dense"
                  name="nomeDeUsuário"
                  onChange={handleChange}
                  type="text"
                  value={data.values.nomeDeUsuário || ''}              
                />
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}>
                <TextField
                  error={hasError('email')}
                  fullWidth
                  helperText={
                    hasError('email') ? data.errors.email[0] : 'O final do email será salvo com @newplot.com.br'
                  }
                  inputProps={{ 'data-testid': 'input-email' }}
                  // eslint-disable-next-line
                  InputProps={{
                    endAdornment: <InputAdornment position="end">@newplot.com.br</InputAdornment>,
                  }}
                  label="E-mail"
                  margin="dense"
                  name="email"
                  onChange={handleChange}
                  value={data.values.email || ''}
                />
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}>
                <MuiPickersUtilsProvider
                  libInstance={moment}
                  locale={locale}
                  utils={MomentUtils}>       
                  <DatePicker
                    disableFuture
                    format="DD/MM/YYYY"
                    label="Data de nascimento"
                    onChange={date => setSelectedDate(date)}
                    openTo="year"
                    required
                    value={selectedDate}
                    views={['year', 'month', 'date']}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="level-simple">Nível de usuário</InputLabel>
                  <Select
                    inputProps={{
                      name: 'nivel',
                    }}
                    onChange={handleChange}
                    value={data.values.nivel}
                  >
                    <MenuItem value={0}>Normal</MenuItem>
                    <MenuItem value={1}>Administrador</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={handleClose}
            >
            Cancelar
            </Button>
            <Button
              color="primary"
              disabled={!data.isValid || loading}
              type="submit"
            >
              {loading ? <CircularProgress /> : 'Cadastrar'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

Form.propTypes = {
  data: PropTypes.shape(),
  loadUsers: PropTypes.func,
  openForm: PropTypes.bool,
  setData: PropTypes.func,
  setOpenForm: PropTypes.func,
};
