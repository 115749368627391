import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import MaterialTable from 'material-table';
import { toast } from 'react-toastify';
import axios from 'axios';
import moment from 'moment';

import { BudgetToolbar } from '../components';
import BudgetCreate from '../BudgetCreate';
import BudgetUpdate from '../BudgetUpdate';
import BudgetView from '../BudgetView';
import api from '../../../services/api';
import { formatPrice } from '../../../util/formatCurrency';
import SearchInput from 'components/SearchInput';
import { Box, Typography } from '@material-ui/core';
import useDebounce from 'hooks/useDebounce';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
    background: '#fff'
  },
  table: {
    boxShadow: 'none',
    background: '#fff'
  }
}));

export default function BudgetList() {
  const classes = useStyles();

  const [objPayment, setObjPayment] = useState({});
  const [payments, setPayments] = useState([]);

  const [budgets, setBudgets] = useState({
    columns: [
      { title: 'Cliente', field: 'customer.name', editable: 'never' },
      { title: 'Total', field: 'amountFormatted', editable: 'never' },
      { title: 'Forma de pagamento', field: 'payment.id', lookup: objPayment },
      { title: 'Data de criação', field: 'dateFormatted', editable: 'never' },
      {
        title: 'Status',
        field: 'status',
        lookup: { 0: 'Pendente', 1: 'Aprovado' }
      },
      { title: 'Data de aprovação', field: 'approvalDateFormatted' }
    ],
    data: []
  });
  const [products, setProducts] = useState({ data: [] });
  const [productData, setProductData] = useState({});
  const [customers, setCustomers] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [openFormUpdate, setOpenFormUpdate] = useState(false);
  const [openPreviewForm, setOpenPreviewForm] = useState(false);
  const [selectedBudget, setSelectedBudget] = useState({});
  const [query, setQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
    total: 0,
    lastPage: 0
  });

  const debouncedSearch = useDebounce(handleSearchChange, 800);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setObjPayment(
      payments.reduce(function(acc, cur) {
        acc[cur.id] = cur.name;

        return acc;
      }, objPayment)
    );
    // eslint-disable-next-line
  }, [payments]);

  useEffect(() => {
    Object.keys(productData).length === 0 && productData.constructor === Object
      ? setOpenPreviewForm(false)
      : setOpenPreviewForm(true);
    // eslint-disable-next-line
  }, [productData]);

  function calculateTotalOriginal(amountValue, discountValue) {
    if (discountValue) {
      const calculatedValue = amountValue + discountValue;

      return formatPrice(calculatedValue);
    } else {
      return formatPrice(amountValue);
    }
  }

  function calculateDiscountedTotal(amountValue, discountValue) {
    const calculatedValue = amountValue - discountValue;

    return formatPrice(calculatedValue);
  }

  function handleSearchChange(searchTerm) {
    setQuery(searchTerm);
    loadData(1, pagination.perPage, searchTerm);
  }

  async function loadData(
    page = pagination.page,
    perPage = pagination.perPage,
    searchTerm = query
  ) {
    setIsLoading(true);

    const endpoint = searchTerm ? `budgets/search/${searchTerm}` : 'budgets';

    axios
      .all([
        api.get(endpoint, {
          params: { page, limit: perPage }
        }),
        api.get('payments'),
        api.get('products/all'),
        api.get('customers/all')
      ])
      .then(
        axios.spread(function(
          budgetsResponse,
          paymentsResponse,
          productsResponse,
          customersResponse
        ) {
          setPagination({
            page: budgetsResponse.data.page,
            perPage: budgetsResponse.data.perPage,
            total: budgetsResponse.data.total,
            lastPage: budgetsResponse.data.lastPage
          });

          setBudgets({
            ...budgets,
            data: budgetsResponse.data.data.map(budget => {
              return {
                ...budget,
                amountOriginalFormatted:
                  budget.discount && budget.amount
                    ? calculateTotalOriginal(
                        Number(budget.amount),
                        Number(budget.discount.amount)
                      )
                    : calculateTotalOriginal(Number(budget.amount)),
                amountFormatted: formatPrice(Number(budget.amount)),
                totalDiscount:
                  budget.discount && budget.amount
                    ? calculateDiscountedTotal(
                        Number(budget.amount),
                        Number(budget.discount.amount)
                      )
                    : null,
                discount: budget.discount && {
                  ...budget.discount,
                  discountFormatted: formatPrice(Number(budget.discount.amount))
                },
                dateFormatted: moment(budget.creation_date).format(
                  'DD/MM/YYYY'
                ),
                amountOfBoards:
                  budget.products &&
                  budget.products.reduce((accumulator, currentValue) => {
                    return accumulator + currentValue.pivot.quantity;
                  }, 0),
                approvalDateFormatted: !budget.approval_date
                  ? 'Em andamento'
                  : moment(budget.approval_date, 'YYYY/MM/DD').format(
                      'DD/MM/YYYY'
                    )
              };
            })
          });

          setPayments(paymentsResponse.data);

          setProducts({
            ...products,
            data: productsResponse.data.map(product => ({
              ...product,
              priceFormatted: !product.price
                ? 'Não há preço cadastrado'
                : formatPrice(Number(product.price))
            }))
          });

          setCustomers(customersResponse.data);
        })
      )
      .catch(() => {
        toast.error('Falha ao carregar os dados.');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  async function deleteBudgets(budget) {
    try {
      const { data } = await api.delete(`budgets/${budget.id}`);
      if (data.success) {
        toast.success(data.success.message);
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.error.message);
      } else {
        toast.error('Error interno, por favor tente em instantes.');
      }
    } finally {
      loadData();
    }
  }

  function editBudget(data) {
    setSelectedBudget(data);
    setOpenFormUpdate(true);
  }

  function handleClearSearch() {
    setQuery('');
    loadData(1, pagination.perPage, '');
  }

  return (
    <div className={classes.root}>
      <BudgetToolbar setOpenForm={setOpenForm} />
      <div className={classes.content}>
        <Box borderRadius={4} className={classes.toolbar}>
          <Typography variant="h6">Orçamentos</Typography>
          <SearchInput
            onChange={e => {
              setQuery(e.target.value);
              debouncedSearch(e.target.value);
            }}
            onClear={handleClearSearch}
            placeholder="Buscar orçamento"
            value={query}
          />
        </Box>
        <MaterialTable
          actions={[
            {
              icon: 'create',
              tooltip: 'Editar obra',
              onClick: (_, rowData) => {
                editBudget(rowData);
              }
            },
            {
              icon: 'remove_red_eye',
              tooltip: 'Visualizar',
              onClick: (_, rowData) =>
                setProductData({
                  ...rowData,
                  statusFormatted:
                    rowData.status === 1
                      ? `Aprovado em ${moment(
                          rowData.approval_date,
                          'YYYY/MM/DD'
                        ).format('DD/MM/YYYY')}`
                      : 'Pendente',
                  products: rowData.products.map(product => ({
                    ...product,
                    priceFormatted: product.price && formatPrice(product.price),
                    totalValuePerProduct: formatPrice(
                      product.price * product.pivot.quantity
                    )
                  }))
                })
            }
          ]}
          columns={budgets.columns}
          components={{
            Container: props => <div {...props} className={classes.table} />
          }}
          data={budgets.data}
          editable={{
            onRowDelete: oldData =>
              new Promise(resolve => {
                setTimeout(() => {
                  resolve();
                  const data = [...budgets.data];
                  const budgetSelected = data[data.indexOf(oldData)];
                  deleteBudgets(budgetSelected);
                }, 600);
              })
          }}
          isLoading={isLoading}
          localization={{
            header: {
              actions: 'Ações'
            },
            body: {
              emptyDataSourceMessage: 'Não há registros a serem exibidos',
              deleteTooltip: 'Deletar orçamento',
              editTooltip: 'Editar orçamento',
              editRow: {
                saveTooltip: 'Confirmar',
                cancelTooltip: 'Cancelar',
                deleteText: 'Tem certeza de que deseja excluir este orçamento?'
              }
            },
            toolbar: {
              searchPlaceholder: 'Buscar orçamento',
              searchTooltip: 'Buscar'
            },
            pagination: {
              labelRowsSelect: 'linhas',
              labelDisplayedRows: '{from}-{to} de {count}',
              firstTooltip: 'Primeira página',
              lastTooltip: 'Última página',
              previousTooltip: 'Página anterior',
              nextTooltip: 'Próxima página'
            }
          }}
          onChangePage={newPage => {
            setPagination(prev => ({ ...prev, page: newPage + 1 }));
            loadData(newPage + 1, pagination.perPage, query);
          }}
          onChangeRowsPerPage={newPerPage => {
            setPagination(prev => ({ ...prev, perPage: newPerPage, page: 1 }));
            loadData(1, newPerPage, query);
          }}
          options={{
            pageSize: pagination.perPage,
            search: false,
            toolbar: false,
            pageSizeOptions: [5, 10, 20]
          }}
          page={pagination.page - 1}
          totalCount={pagination.total}
        />
      </div>
      <BudgetCreate
        customers={customers}
        loadData={loadData}
        openForm={openForm}
        products={products}
        setOpenForm={setOpenForm}
      />
      <BudgetUpdate
        loadData={loadData}
        openFormUpdate={openFormUpdate}
        payments={payments}
        products={products}
        selectedBudget={selectedBudget}
        setOpenFormUpdate={setOpenFormUpdate}
        setProducts={setProducts}
        setSelectedBudget={setSelectedBudget}
      />
      <BudgetView
        openPreviewForm={openPreviewForm}
        productData={productData}
        setOpenPreviewForm={setOpenPreviewForm}
        setProductData={setProductData}
      />
    </div>
  );
}
