import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Typography
} from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import moment from 'moment';
import 'moment/locale/pt-br';
import MomentUtils from '@date-io/moment';
import { toast } from 'react-toastify';

import api from '../../services/api';

const locale = moment.locale('pt-br');

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
    email: {
      message: 'não parece válido'
    },
    length: {
      maximum: 64
    }
  },
  senhaAtual: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  novaSenha: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  confirmarNovaSenha: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
    equality: {
      attribute: 'novaSenha',
      message: 'não é igual a %{attribute}'
    }
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth_background.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  activeUserButton: {
    margin: theme.spacing(2, 0),
    '&:hover': {
      backgroundColor: '#000',
      borderColor: '#000',
      color: '#fff',
      boxShadow: 'none',
    },
  }
}));

function EnableUser({ history }){
  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  function handleChange(event) {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  }

  function handleBack() {
    history.goBack();
  }

  async function handleEnableUser(event){
    event.preventDefault();

    try {
      setLoading(true);

      const { email, senhaAtual, novaSenha, confirmarNovaSenha } = formState.values;

      const response = await api.post('users/enable', {
        email,
        birth_date: moment(selectedDate).format('YYYY-MM-DD'),
        current_password: senhaAtual,
        password: novaSenha,
        password_confirmation: confirmarNovaSenha
      });

      if (response.data.success) {
        toast.success(response.data.success.message)
        setLoading(false)
        history.push('/sign-in');
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.error.message);
        setLoading(false);
      } else {
        toast.error('Error interno, por favor tente em instantes.')
      }
    }
  }

  function hasError(field){
    return formState.touched[field] && formState.errors[field] ? true : false;
  }

  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
      >
        <Grid
          className={classes.quoteContainer}
          item
          lg={5}
        >
          <div className={classes.quote} />
        </Grid>
        <Grid
          className={classes.content}
          item
          lg={7}
          xs={12}
        >
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div className={classes.contentBody}>
              <form
                className={classes.form}
                onSubmit={handleEnableUser}
              >
                <Typography
                  className={classes.title}
                  variant="h2"
                >
                  Ativar usuário
                </Typography>
                <Typography
                  color="textSecondary"
                  gutterBottom
                >
                  Informe seus dados
                </Typography>
                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    <TextField
                      error={hasError('email')}
                      fullWidth
                      helperText={
                        hasError('email') ? formState.errors.email[0] : null
                      }
                      inputProps={{ 'data-testid': 'input-email' }}
                      label="Seu e-mail"
                      name="email"
                      onChange={handleChange}
                      type="text"
                      value={formState.values.email || ''}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      locale={locale}
                      utils={MomentUtils}>
                      <DatePicker
                        disableFuture
                        format="DD/MM/YYYY"
                        inputVariant="outlined"
                        label="Data de nascimento"
                        onChange={date => setSelectedDate(date)}
                        openTo="year"
                        required
                        value={selectedDate}
                        views={['year', 'month', 'date']}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    <TextField
                      error={hasError('senhaAtual')}
                      fullWidth
                      helperText={
                        hasError('senhaAtual') ? formState.errors.senhaAtual[0] : null
                      }
                      inputProps={{ 'data-testid': 'input-password' }}
                      label="Senha atual"
                      name="senhaAtual"
                      onChange={handleChange}
                      type="password"
                      value={formState.values.senhaAtual || ''}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    <TextField
                      error={hasError('novaSenha')}
                      fullWidth
                      helperText={
                        hasError('novaSenha') ? formState.errors.novaSenha[0] : null
                      }
                      label="Nova senha"
                      name="novaSenha"
                      onChange={handleChange}
                      type="password"
                      value={formState.values.novaSenha || ''}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    <TextField
                      error={hasError('confirmarNovaSenha')}
                      fullWidth
                      helperText={
                        hasError('confirmarNovaSenha') ? formState.errors.confirmarNovaSenha[0] : null
                      }
                      label="Confirmar nova senha"
                      name="confirmarNovaSenha"
                      onChange={handleChange}
                      type="password"
                      value={formState.values.confirmarNovaSenha || ''}
                      variant="outlined"
                    />
                  </Grid>
                  <Button
                    className={classes.activeUserButton}
                    color="inherit"
                    disableFocusRipple
                    disabled={!formState.isValid}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="outlined"
                  >
                    { loading ?  'Carregando...' : 'Ativar usuário'}
                  </Button>
                </Grid>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

EnableUser.propTypes = {
  history: PropTypes.object
};

export default withRouter(EnableUser);
