import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Form from './Form';

export default function BudgetCreate({ openForm, setOpenForm, loadData, payments, products, customers }) {

  const [data, setData] = useState({
    isValid: false,
    values: {
      products: []
    },
    touched: {},
    errors: {}
  });

  return (
    <Form
      customers={customers}
      data={data}
      loadData={loadData}
      openForm={openForm}
      payments={payments}
      products={products}
      setData={setData}
      setOpenForm={setOpenForm}
    />
  );
}

BudgetCreate.propTypes = {
  customers: PropTypes.array,
  loadData: PropTypes.func,
  openForm: PropTypes.bool,
  payments: PropTypes.array,
  products: PropTypes.shape(),
  setOpenForm: PropTypes.func
};
