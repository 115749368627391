import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import TimelineIcon from '@material-ui/icons/Timeline';
import HomeIcon from '@material-ui/icons/Home';
import GroupAddIcon from '@material-ui/icons/GroupAdd';

import { Profile, SidebarNav } from './components';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

export default function Sidebar({
  open,
  variant,
  onClose,
  className,
  ...rest
}) {
  const classes = useStyles();

  const pages = [
    {
      title: 'Dashboard',
      href: '/dashboard',
      icon: <DashboardIcon />
    },
    {
      title: 'Produtos',
      href: '/products',
      icon: <ShoppingBasketIcon />
    },
    {
      title: 'Clientes',
      href: '/customers',
      icon: <PeopleIcon />
    },
    {
      title: 'Obras',
      href: '/constructions',
      icon: <HomeIcon />
    },
    {
      title: 'Solicitantes',
      href: '/requesters',
      icon: <GroupAddIcon />
    },
    {
      title: 'Formas de pagamento',
      href: '/payments',
      icon: <AttachMoneyIcon />
    },
    {
      title: 'Logs',
      href: '/logs',
      icon: <LibraryBooksIcon />
    },
    {
      title: 'Usuários',
      href: '/users',
      icon: <PeopleIcon />
    },
    {
      title: 'Saídas',
      href: '/exits',
      icon: <TrendingDownIcon />
    },
    {
      title: 'Orçamentos',
      href: '/budgets',
      icon: <NoteAddIcon />
    },
    {
      title: 'Relatórios',
      href: '/reports',
      icon: <TimelineIcon />
    }
  ];

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}>
      <div
        {...rest}
        className={clsx(classes.root, className)}>
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav
          className={classes.nav}
          pages={pages} />
      </div>
    </Drawer>
  );
}

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};
