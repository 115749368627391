import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import MaterialTable from 'material-table';
import { toast } from 'react-toastify';
import moment from 'moment';

import { ExitToolbar } from '../components';
import ExitCreate from '../ExitCreate';
import api from '../../../services/api';
import { formatPrice } from '../../../util/formatCurrency';
import { formatDate, reverseFormatDate } from '../../../util/formatDate';
import useDebounce from 'hooks/useDebounce';
import { Box, Typography } from '@material-ui/core';
import SearchInput from 'components/SearchInput';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
    background: '#fff'
  },
  table: {
    boxShadow: 'none',
    background: '#fff'
  }
}));

export default function ExitList() {
  const classes = useStyles();

  const [exits, setExits] = useState({
    columns: [
      { title: 'Nome', field: 'name' },
      { title: 'Data', field: 'dateFormatted' },
      { title: 'Valor', field: 'priceFormatted' }
    ],
    data: []
  });
  const [openForm, setOpenForm] = useState(false);
  const [query, setQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
    total: 0,
    lastPage: 0
  });

  const debouncedSearch = useDebounce(handleSearchChange, 800);

  useEffect(() => {
    loadExits();
    // eslint-disable-next-line
  }, []);

  function handleSearchChange(searchTerm) {
    setQuery(searchTerm);
    loadExits(1, pagination.perPage, searchTerm);
  }

  async function loadExits(
    page = pagination.page,
    perPage = pagination.perPage,
    searchTerm = query
  ) {
    try {
      setIsLoading(true);

      const endpoint = searchTerm ? `exits/search/${searchTerm}` : 'exits';

      const { data } = await api.get(endpoint, {
        params: { page, limit: perPage }
      });

      setPagination({
        page: data.page,
        perPage: data.perPage,
        total: data.total,
        lastPage: data.lastPage
      });

      const newData = data.data.map(exit => ({
        ...exit,
        dateFormatted: formatDate(exit.date),
        priceFormatted: !exit.price
          ? 'Não há preço cadastrado'
          : formatPrice(exit.price)
      }));

      setExits({ ...exits, data: newData });
    } catch (error) {
      toast.error('Falha ao carregar os dados.');
    } finally {
      setIsLoading(false);
    }
  }

  async function updateExit(exit) {
    let date = exit.dateFormatted;

    if (moment(date, 'DD/MM/YYYY', true).isValid()) {
      try {
        date = reverseFormatDate(exit.dateFormatted);
        const price = Number(exit.price);

        const { data } = await api.put(`exits/${exit.id}`, {
          name: exit.name,
          date,
          price
        });

        if (data.success) {
          toast.success(data.success.message);
        }
      } catch (error) {
        if (error.response) {
          error.response.data.map(error => toast.error(error.message));
        } else {
          toast.error('Error interno, por favor tente em instantes.');
        }
      } finally {
        loadExits();
      }
    } else {
      toast.error('Por favor, informe uma data válida');
    }
  }

  async function deleteExit(exit) {
    try {
      const { data } = await api.delete(`exits/${exit.id}`);

      if (data.success) {
        toast.success('Saída excluída com sucesso.');
        loadExits();
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.error.message);
      } else {
        toast.error('Error interno, por favor tente em instantes.');
      }
    }
  }

  function handleClearSearch() {
    setQuery('');
    loadExits(1, pagination.perPage, '');
  }

  return (
    <div className={classes.root}>
      <ExitToolbar setOpenForm={setOpenForm} />
      <div className={classes.content}>
        <Box borderRadius={4} className={classes.toolbar}>
          <Typography variant="h6">Saídas</Typography>
          <SearchInput
            onChange={e => {
              setQuery(e.target.value);
              debouncedSearch(e.target.value);
            }}
            onClear={handleClearSearch}
            placeholder="Buscar cliente"
            value={query}
          />
        </Box>
        <MaterialTable
          columns={exits.columns}
          components={{
            Container: props => <div {...props} className={classes.table} />
          }}
          data={exits.data}
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise(resolve => {
                setTimeout(() => {
                  resolve();
                  const data = [...exits.data];
                  const exitSelected = (data[data.indexOf(oldData)] = newData);
                  updateExit(exitSelected);
                }, 600);
              }),
            onRowDelete: oldData =>
              new Promise(resolve => {
                setTimeout(() => {
                  resolve();
                  const data = [...exits.data];
                  const exitSelected = data[data.indexOf(oldData)];
                  deleteExit(exitSelected);
                }, 600);
              })
          }}
          isLoading={isLoading}
          localization={{
            header: {
              actions: 'Ações'
            },
            body: {
              emptyDataSourceMessage: 'Não há registros a serem exibidos',
              deleteTooltip: 'Deletar saídas',
              editRow: {
                saveTooltip: 'Confirmar',
                cancelTooltip: 'Cancelar',
                deleteText: 'Tem certeza de que deseja excluir esta saída?'
              }
            },
            toolbar: {
              searchPlaceholder: 'Buscar saída',
              searchTooltip: 'Buscar'
            },
            pagination: {
              labelRowsSelect: 'linhas',
              labelDisplayedRows: '{from}-{to} de {count}',
              firstTooltip: 'Primeira página',
              lastTooltip: 'Última página',
              previousTooltip: 'Página anterior',
              nextTooltip: 'Próxima página'
            }
          }}
          onChangePage={newPage => {
            setPagination(prev => ({ ...prev, page: newPage + 1 }));
            loadExits(newPage + 1, pagination.perPage, query);
          }}
          onChangeRowsPerPage={newPerPage => {
            setPagination(prev => ({ ...prev, perPage: newPerPage, page: 1 }));
            loadExits(1, newPerPage, query);
          }}
          options={{
            pageSize: pagination.perPage,
            search: false,
            toolbar: false,
            pageSizeOptions: [5, 10, 20]
          }}
          page={pagination.page - 1}
          title="Saídas"
          totalCount={pagination.total}
        />
      </div>
      <ExitCreate
        loadExits={loadExits}
        openForm={openForm}
        setOpenForm={setOpenForm}
      />
    </div>
  );
}
