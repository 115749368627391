import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Hidden, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import InputIcon from '@material-ui/icons/Input';
import { toast } from 'react-toastify';

import history from '../../../../services/history';
import { logout } from '../../../../services/auth';
import api from '../../../../services/api';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  toolbar: {
    background: '#000'
  },
  logo: {
    width: 150,
  }
}));

export default function Topbar({className, onSidebarOpen, ...rest}) {
  const classes = useStyles();

  async function handleSignOut() {
    try {
      const response = await api.get('logout');

      if (response.status === 205) {
        await logout()
        history.push('/sign-in');
        toast.success('Logout realizado com successo');
      }
    } catch (error) {
      toast.error('Falha ao realizar o logout, tente em instantes');
    }
  }

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar className={classes.toolbar}>
        <img
          alt="Logo"
          className={classes.logo}
          src="/images/logos/logo.png"
        />
        <div className={classes.flexGrow} />
        <IconButton
          color="inherit"
          data-testid="button-account"
          disableFocusRipple
          onClick={() => history.push('/account')}
        >
          <SupervisedUserCircleIcon />
        </IconButton>
        <IconButton
          className={classes.signOutButton}
          color="inherit"
          data-testid="button-logout"
          onClick={() => handleSignOut()}
        >
          <InputIcon />
        </IconButton>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onSidebarOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};
