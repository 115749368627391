import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import MaterialTable from 'material-table';
import { toast } from 'react-toastify';

import { PaymentToolbar } from '../components';
import PaymentCreate from '../PaymentCreate';
import api from '../../../services/api';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
}));

export default function PaymentList() {
  const classes = useStyles();

  const [payments, setPayments] = useState({
    columns: [
      { title: 'Nome', field: 'name' },
    ],
    data: []
  });
  const [openForm, setOpenForm] = useState(false);

  useEffect(() => {
    loadPayments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function loadPayments() {
    try {
      const { data } = await api.get('payments');

      setPayments({...payments, data});
    } catch (error) {
      toast.error('Falha ao carregar os dados.');
    }
  }

  async function updatePayment(payment) {
    try {
      const { data } = await api.put(`payments/${payment.id}`, {
        name: payment.name,
      })
  
      if (data.success) {
        toast.success(data.success.message);
      }
    } catch (error) {
      if (error.response) {
        error.response.data.map(error => toast.error(error.message))
      } else {
        toast.error('Error interno, por favor tente em instantes.')
      }
    } finally {
      loadPayments();
    }
  }

  async function deletePayment(payment) {
    try {
      const { data } = await api.delete(`payments/${payment.id}`)

      if (data.success) {
        toast.success(data.success.message);
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.error.message);
      } else {
        toast.error('Error interno, por favor tente em instantes.')
      }
    } finally {
      loadPayments();
    }
  }

  return (
    <div className={classes.root}>
      <PaymentToolbar
        setOpenForm={setOpenForm}
      />
      <div className={classes.content}>
        <MaterialTable
          columns={payments.columns}
          data={payments.data}
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise(resolve => {
                setTimeout(() => {
                  resolve();
                  const data = [...payments.data];
                  const paymentSelected = data[data.indexOf(oldData)] = newData;
                  updatePayment(paymentSelected)
                }, 600);
              }),
            onRowDelete: oldData =>
              new Promise(resolve => {
                setTimeout(() => {
                  resolve();
                  const data = [...payments.data];
                  const paymentSelected = data[data.indexOf(oldData)];
                  deletePayment(paymentSelected)
                }, 600);
              }),
          }}
          localization={{
            header: {
              actions: 'Ações'
            },
            body: {
              emptyDataSourceMessage: 'Não há registros a serem exibidos',
              deleteTooltip: 'Deletar forma de pagamento',
              editTooltip: 'Editar cliente',
              editRow: {
                saveTooltip: 'Confirmar',
                cancelTooltip: 'Cancelar',
                deleteText: 'Tem certeza de que deseja excluir esta forma de pagamento?'
              }
            },
            toolbar: {
              searchPlaceholder: 'Buscar forma de pagamento',
              searchTooltip: 'Buscar'
            },
            pagination: {
              labelRowsSelect: 'linhas',
              labelDisplayedRows: '{from}-{to} de {count}',
              firstTooltip: 'Primeira página',
              lastTooltip: 'Última página',
              previousTooltip: 'Página anterior',
              nextTooltip: 'Próxima página'
            }
          }}
          title="Formas de pagamento"
        />
      </div>
      <PaymentCreate
        loadPayments={loadPayments}
        openForm={openForm}
        setOpenForm={setOpenForm}
      />
    </div>
  );
}
