import React from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  textField: {
    width: 600,
  },
  quantity: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonRemove: {
    background: '#ff1744',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#ff5252',
      borderColor: '#ff1744',
    },
  },
});

export default function AddProduct({ field }) {
  const classes = useStyles();

  return (
    <Grid
      alignItems="center" 
      container
      direction="row"
      justify="space-between"
    >
      <Grid
        item
        lg={7}
        md={7}
        sm={7}
        xs={12}
      >
        <Grid
          alignItems="center"
          container
          direction="row"
          justify="space-between"
        >
          <TextField
            className={classes.textField}
            disabled
            // eslint-disable-next-line
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ 'data-testid': 'input-product-name' }}
            label="Produto"
            margin="dense"
            name="products"
            value={`${field.name} - ${field.priceFormatted}`}
            variant="outlined"
          />
        </Grid>
      </Grid>
      <Grid
        item
        lg={5}
        md={5}
        sm={5}
        xs={12}
      >
        <Grid
          alignItems="center"
          container
          direction="row"
          justify="space-evenly"
        >
          <Typography
            className={classes.quantity}
          > Quantidade: {field.pivot.quantity} </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

AddProduct.propTypes = {
  field: PropTypes.object,
};
