import React from 'react'
import Select from 'react-select'
import PropTypes from 'prop-types';

const styles = {
  control: (base) => ({
    ...base,
    minHeight: 50,
  }),
  dropdownIndicator: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  clearIndicator: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
};

export default function Autocomplete({ customers, setValue }){

  return (
    <Select
      onChange={setValue}
      options={customers}
      placeholder="Selecione o cliente"
      styles={styles}
    />
  )
}

Autocomplete.propTypes = {
  customers: PropTypes.array,
  setValue: PropTypes.func,
}
