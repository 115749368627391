export const { format: formatPrice } = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL'
});

// export const reverseFormatPrice = value => Number(value.split('.').join(',').split(',').join('.').replace(/[^0-9.]/g, ''));

export const reverseFormatPrice = value => {
  const withoutDollarSign = value.replace('R$', '').trim(); // Remove o 'R$' e espaços
  const group = new Intl.NumberFormat('pt-BR').format(1111).replace(/1/g, '');
  const decimal = new Intl.NumberFormat('pt-BR').format(1.1).replace(/1/g, '');

  let reversedVal = withoutDollarSign.replace(
    new RegExp('\\' + group, 'g'),
    ''
  );
  reversedVal = reversedVal.replace(new RegExp('\\' + decimal, 'g'), '.');

  const parsedValue = parseFloat(reversedVal);
  return Number.isNaN(parsedValue) ? 0 : parsedValue.toFixed(2);
};
