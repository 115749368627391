import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import axios from 'axios';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';
import MoneyIcon from '@material-ui/icons/Money';
import { toast } from 'react-toastify';

import api from '../../../../services/api';
import { formatPrice } from '../../../../util/formatCurrency';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
}));

export default function MonthlySpend({className, ...rest}) {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [totalCurrentMonthSpent, setTotalCurrentMonthSpent] = useState({})
  const [previousMonthTotalSpend, setpreviousMonthTotalSpend] = useState({});

  useEffect(() => {
    loadExits()
    // eslint-disable-next-line
  }, []);

  function checkForCurrentMonthTotal(responseCurrentMonthExits) {
    responseCurrentMonthExits.data.total ? setTotalCurrentMonthSpent({
      totalFormatted: formatPrice(responseCurrentMonthExits.data.total)
    }) : setTotalCurrentMonthSpent({
      totalFormatted: formatPrice(0)
    });
  }

  function checkForThePreviousMonthsTotal(responseExitsPreviousMonth) {
    responseExitsPreviousMonth.data.total ? setpreviousMonthTotalSpend({
      totalFormatted: `${formatPrice(responseExitsPreviousMonth.data.total)} - mês passado`
    }) : setpreviousMonthTotalSpend({
      totalFormatted: 'Sem saídas no mês anterior'
    });
  }

  async function loadExits() {
    try {
      setLoading(true);
      const currentDate = new Date();

      const yearOfTheCurrentMonth = moment(currentDate).format('YYYY');
      const currentMonth = moment(currentDate).format('MM');

      const dateOfPreviousMonth = moment(currentDate).subtract(1,'months').endOf('month').format('YYYY-MM-DD');
      const previousMonthsYear = moment(dateOfPreviousMonth).format('YYYY');
      const lastMonth = moment(dateOfPreviousMonth).format('MM');

      await axios.all([
        api.get(`/reports/general/outputs/year/${yearOfTheCurrentMonth}/month/${currentMonth}`),
        api.get(`/reports/general/outputs/year/${previousMonthsYear}/month/${lastMonth}`),
      ])
        .then(
          axios.spread(function (responseCurrentMonthExits, responseExitsPreviousMonth) {
            checkForCurrentMonthTotal(responseCurrentMonthExits);
            checkForThePreviousMonthsTotal(responseExitsPreviousMonth);
          })
        )
        .catch(() => {
          toast.error('Falha ao carregar alguns dados, por favor recarregue a página.');
        })
    } catch (error) {
      toast.error(error.response.data.error.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
        {loading ? <CircularProgress /> : (
        <>
          <Grid
            container
            justify="space-between"
          >
            <Grid item>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
                variant="body2"
              >
                Gastos do mês
              </Typography>
              <Typography variant="h3">{totalCurrentMonthSpent.totalFormatted || ''}</Typography>
            </Grid>
            <Grid item>
              <Avatar className={classes.avatar}>
                <MoneyIcon className={classes.icon} />
              </Avatar>
            </Grid>
          </Grid>
          <div className={classes.difference}>
            <Typography
              className={classes.caption}
              variant="caption"
            >
              {previousMonthTotalSpend.totalFormatted || ''}
            </Typography>
          </div>
        </>
        )}
      </CardContent>
    </Card>
  );
}

MonthlySpend.propTypes = {
  className: PropTypes.string
};
