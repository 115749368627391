import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import MaterialTable from 'material-table';
import { toast } from 'react-toastify';

import { ProductsToolbar } from '../components';
import ProductCreate from '../ProductCreate';
import api from '../../../services/api';
import { formatPrice } from '../../../util/formatCurrency';
import { Box, Typography } from '@material-ui/core';
import SearchInput from 'components/SearchInput';
import useDebounce from 'hooks/useDebounce';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
    background: '#fff'
  },
  table: {
    boxShadow: 'none',
    background: '#fff'
  }
}));

export default function ProductList() {
  const classes = useStyles();
  const [products, setProducts] = useState({
    columns: [
      { title: 'Nome', field: 'name' },
      { title: 'Preço', field: 'priceFormatted' }
    ],
    data: []
  });
  const [query, setQuery] = useState('');
  const [openForm, setOpenForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
    total: 0,
    lastPage: 0
  });
  const debouncedSearch = useDebounce(handleSearchChange, 800);

  useEffect(() => {
    loadProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function loadProducts(
    page = pagination.page,
    perPage = pagination.perPage,
    searchTerm = query
  ) {
    setIsLoading(true);
    try {
      const endpoint = searchTerm
        ? `products/search/${searchTerm}`
        : 'products';
      const { data } = await api.get(endpoint, {
        params: { page, limit: perPage }
      });

      setProducts(prev => ({ ...prev, data: formatData(data.data) }));
      setPagination({
        page: data.page,
        perPage: data.perPage,
        total: data.total,
        lastPage: data.lastPage
      });
    } catch (error) {
      toast.error('Falha ao carregar os dados.');
    } finally {
      setIsLoading(false);
    }
  }

  function formatData(data) {
    return data.map(product => ({
      ...product,
      priceFormatted: product.price
        ? formatPrice(product.price)
        : 'Não há preço cadastrado'
    }));
  }

  async function handleProductUpdate(product) {
    try {
      const price = Number(product.price);
      if (Number(price) <= 0)
        return toast.error('O valor tem que ser maior que 0');

      const { data } = await api.put(`products/${product.id}`, {
        name: product.name,
        price
      });
      if (data.success) toast.success(data.success.message);
    } catch (error) {
      handleError(error);
    } finally {
      loadProducts();
    }
  }

  async function handleProductDelete(product) {
    try {
      const { data } = await api.delete(`products/${product.id}`);
      if (data.success) toast.success(data.success.message);
    } catch (error) {
      handleError(error);
    } finally {
      loadProducts();
    }
  }

  function handleError(error) {
    if (error.response) {
      if (Array.isArray(error.response.data)) {
        return error.response.data.forEach(err => {
          toast.error(err.message);
        });
      }

      if (error.response.data.error) {
        return toast.error(error.response.data.error.message);
      }
    } else {
      toast.error('Erro interno, por favor tente novamente.');
    }
  }

  function handleSearchChange(searchTerm) {
    setQuery(searchTerm);
    loadProducts(1, pagination.perPage, searchTerm);
  }

  function handleClearSearch() {
    setQuery('');
    loadProducts(1, pagination.perPage, '');
  }

  return (
    <div className={classes.root}>
      <ProductsToolbar setOpenForm={setOpenForm} />
      <div className={classes.content}>
        <Box borderRadius={4} className={classes.toolbar}>
          <Typography variant="h6">Produtos</Typography>
          <SearchInput
            onChange={e => {
              setQuery(e.target.value);
              debouncedSearch(e.target.value);
            }}
            onClear={handleClearSearch}
            placeholder="Buscar produto"
            value={query}
          />
        </Box>
        <MaterialTable
          columns={products.columns}
          components={{
            Container: props => <div {...props} className={classes.table} />
          }}
          data={products.data}
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise(resolve => {
                resolve();
                const updatedData = [...products.data];
                const productIndex = updatedData.indexOf(oldData);
                updatedData[productIndex] = newData;
                handleProductUpdate(updatedData[productIndex]);
              }),
            onRowDelete: oldData =>
              new Promise(resolve => {
                resolve();
                handleProductDelete(oldData);
              })
          }}
          isLoading={isLoading}
          localization={{
            header: { actions: 'Ações' },
            body: {
              emptyDataSourceMessage: 'Não há registros a serem exibidos',
              deleteTooltip: 'Deletar produto',
              editTooltip: 'Editar produto',
              editRow: {
                saveTooltip: 'Confirmar',
                cancelTooltip: 'Cancelar',
                deleteText: 'Tem certeza de que deseja excluir este produto?'
              }
            },
            pagination: {
              labelRowsSelect: 'linhas',
              labelDisplayedRows: '{from}-{to} de {count}',
              firstTooltip: 'Primeira página',
              lastTooltip: 'Última página',
              previousTooltip: 'Página anterior',
              nextTooltip: 'Próxima página'
            }
          }}
          onChangePage={newPage => {
            setPagination(prev => ({ ...prev, page: newPage + 1 }));
            loadProducts(newPage + 1, pagination.perPage, query);
          }}
          onChangeRowsPerPage={newPerPage => {
            setPagination(prev => ({ ...prev, perPage: newPerPage, page: 1 }));
            loadProducts(1, newPerPage, query);
          }}
          options={{
            pageSize: pagination.perPage,
            search: false,
            toolbar: false,
            pageSizeOptions: [5, 10, 20]
          }}
          page={pagination.page - 1}
          totalCount={pagination.total}
        />
      </div>
      <ProductCreate
        loadProducts={() =>
          loadProducts(pagination.page, pagination.perPage, query)
        }
        openForm={openForm}
        setOpenForm={setOpenForm}
      />
    </div>
  );
}
