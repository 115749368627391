import { useRef } from 'react';

export default function useDebounce(callback, delay) {
  const debounceTimeoutRef = useRef(null);

  return (...args) => {
    if (debounceTimeoutRef.current) clearTimeout(debounceTimeoutRef.current);
    debounceTimeoutRef.current = setTimeout(() => {
      callback(...args);
    }, delay);
  };
}
