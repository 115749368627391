import React from 'react';
import PropTypes from 'prop-types';

import Form from './Form';

export default function BudgetCreate({ productData, openPreviewForm, setOpenPreviewForm, setProductData }) {

  return (
    <Form
      openPreviewForm={openPreviewForm}
      productData={productData}
      setOpenPreviewForm={setOpenPreviewForm}
      setProductData={setProductData}
    />
  );
}

BudgetCreate.propTypes = {
  openPreviewForm: PropTypes.bool,
  productData: PropTypes.object,
  setOpenPreviewForm: PropTypes.func,
  setProductData: PropTypes.func
};
