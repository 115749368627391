import React from 'react';
import PropTypes from 'prop-types';
import { 
  Document,
  Page,
  View,
  StyleSheet,
  Image,
  Text 
} from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#fff',
    padding: 30,
  },
  imageLogo: {
    width: 200,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  contactInformation: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 20,
  },
  contactText: {
    fontSize: 12,
  },
  containerTitle: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 20,
  },
  title: {
    fontSize: 13,
    fontWeight: 'bold',
  },
  balanceInformation: {
    fontSize: 12,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: 20,
    marginBottom: 20,
  },
  tableSubInformation: {
    display: 'table',
    marginHorizontal: 5,
    marginVertical: 1,
    flexDirection: 'row',
  },
  tableRow: {
    width: '100%',
    flexDirection: 'row',
  },
  tableColHeader: {
    padding: 5,
    border: 1,
    width:'33%',
    textAlign: 'center'
  },
  tableSubInformationTotal: {
    padding: 5,
    border: 1,
    width:'66%',
    textAlign: 'right'
  },
  tableCellHeader: {
    fontSize: 12,
  },
  tableCell: {
    fontSize: 11,
  },
  footer: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  }
})

export default function PdfDocument({ data, month }) {
  return (
    <Document
      title="Relatório">
      <Page
        size="A4"
        style={styles.page}
      >
        <View style={styles.header}>
          <View>
            <Image
              source="/images/logos/logo-black.jpg"
              style={styles.imageLogo}
            />
          </View>
          <View style={styles.contactInformation}>
            <Text style={styles.contactText}>
              Av. Ailton Gomes de Alencar, 1448 - Pirajá
            </Text>
            <Text style={styles.contactText}>
              Juazeiro do Norte - CE
            </Text>
            <Text style={styles.contactText}>
              98853-2791
            </Text>
            <Text style={styles.contactText}>
              contato.newplot@gmail.com
            </Text>
          </View>
        </View>
        <View style={styles.containerTitle}>
          <Text style={styles.title}>BALANÇO DO MÊS DE {month}</Text>
        </View>
        <View style={styles.balanceInformation}>
          <Text>Total bruto: {data.totalFormatted}</Text>
        </View>
        <View style={styles.tableSubInformation}>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Entradas</Text>
            </View>
            <View style={styles.tableSubInformationTotal}>
              <Text style={styles.tableCellHeader}>{data.totalFormattedBudgets}</Text>
            </View>
          </View>
        </View>
        <View style={styles.tableSubInformation}>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Saídas</Text>
            </View>
            <View style={styles.tableSubInformationTotal}>
              <Text style={styles.tableCellHeader}>{data.totalFormattedOutputs}</Text>
            </View>
          </View>
        </View>
        <View style={styles.tableSubInformation}>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Total bruto</Text>
            </View>
            <View style={styles.tableSubInformationTotal}>
              <Text style={styles.tableCellHeader}>{data.totalFormatted}</Text>
            </View>
          </View>
        </View>
        <View style={styles.footer}>
          <Text>Juazeiro do Norte - CE, gerado em {data.dateFormatted}</Text>
        </View>
      </Page>
    </Document>
  );
}

PdfDocument.propTypes = {
  data: PropTypes.object,
  month: PropTypes.string
};
