import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Form from './Form';

export default function BudgetCreate({
  openFormUpdate,
  setOpenFormUpdate,
  loadData,
  payments,
  products,
  customers,
  selectedBudget,
  setSelectedBudget
}) {
  const [data, setData] = useState({
    isValid: false,
    values: {
      products: []
    },
    touched: {},
    errors: {}
  });

  return (
    <Form
      customers={customers}
      data={data}
      loadData={loadData}
      openFormUpdate={openFormUpdate}
      payments={payments}
      products={products}
      selectedBudget={selectedBudget}
      setData={setData}
      setOpenFormUpdate={setOpenFormUpdate}
      setSelectedBudget={setSelectedBudget}
    />
  );
}

BudgetCreate.propTypes = {
  customers: PropTypes.array,
  loadData: PropTypes.func,
  openFormUpdate: PropTypes.bool,
  payments: PropTypes.array,
  products: PropTypes.shape(),
  selectedBudget: PropTypes.object,
  setOpenFormUpdate: PropTypes.func,
  setSelectedBudget: PropTypes.func
};
