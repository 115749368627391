import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

const styles = {
  control: base => ({
    ...base,
    minHeight: 50
  }),
  dropdownIndicator: base => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0
  }),
  clearIndicator: base => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0
  })
};

export default function Autocomplete({
  options,
  setValue,
  placeholder,
  disable
}) {
  return (
    <Select
      isDisabled={disable}
      noOptionsMessage={() => 'Sem Resultados'}
      onChange={setValue}
      options={options}
      placeholder={placeholder}
      styles={styles}
    />
  );
}

Autocomplete.propTypes = {
  disable: PropTypes.bool,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  placeholder: PropTypes.string,
  setValue: PropTypes.func
};
