import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { PDFViewer } from '@react-pdf/renderer';
import 'moment/locale/pt-br';
import { toast } from 'react-toastify';

import TabPanel from '../TabPanel';
import PdfDocument from './PdfDocument';
import Autocomplete from './Autocomplete';

import api from '../../../../services/api';
import { formatPrice } from '../../../../util/formatCurrency';

export default function SecondTab({ theme, index, value: valueIndex }) {
  const [show, setShow] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [data, setData] = useState([]);
  const [value, setValue] = useState(null);

  useEffect(() => {
    async function handleGenerateReport() {
      try {
        const { id } = value.value;

        const response = await api.get(`reports/customers/${id}`);

        if (response.data.length === 0) {
          toast.info('O cliente ainda não possui orçamentos');
          setShow(false);
        } else {
          const data = {
            data: response.data.map(customer => ({
              ...customer,
              formattedCreationDate: moment(customer.creation_date).format(
                'DD/MM/YYYY'
              ),
              formattedApprovalStatus: customer.approval_date
                ? moment(customer.approval_date).format('DD/MM/YYYY')
                : 'Pendente',
              amountFormatted: formatPrice(customer.amount)
            })),
            amountTotal: formatPrice(
              response.data.reduce((accumulator, currentValue) => {
                return accumulator + Number(currentValue.amount);
              }, 0)
            ),
            dateFormatted: moment(new Date()).format('DD/MM/YYYY')
          };
          setData(data);
          setShow(true);
        }
      } catch (error) {
        toast.error('Falha ao gerar relatório, por favor tente em instantes');
        setShow(false);
      }
    }

    value && handleGenerateReport();
  }, [value]);

  useEffect(() => {
    async function fetchCustomers() {
      try {
        const response = await api.get('customers/all');

        const data = response.data.map(customer => ({
          value: customer,
          label: customer.name
        }));
        setCustomers(data);
      } catch (error) {
        toast.error(
          'Falha ao carregar os dados, por favor tente em instantes.'
        );
      }
    }

    fetchCustomers();
  }, []);

  return (
    <TabPanel dir={theme} index={index} value={valueIndex}>
      <Autocomplete customers={customers} setValue={setValue} />
      {show && (
        <PDFViewer
          children={<PdfDocument data={data} />}
          height="700px"
          style={{ marginTop: 10 }}
          width="100%"
        />
      )}
    </TabPanel>
  );
}

SecondTab.propTypes = {
  index: PropTypes.any.isRequired,
  theme: PropTypes.object,
  value: PropTypes.any.isRequired
};
