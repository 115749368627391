import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toast } from 'react-toastify';

import api from '../../../../services/api';

export default function ResetPassword({
  openConfirmPasswordReset,
  setOpenConfirmPasswordReset,
  selectedUser,
  setSelectedUser
}) {

  const [loading, setLoading] = useState(false);

  async function handleResetPassword() {
    try {
      setLoading(true);

      const { data } = await api.put(`password/reset/${selectedUser.id}`)

      if (data.success) {
        toast.success(data.success.message)
      }
    } catch (error) {
      if (error.response) {
        toast.error('Falha ao resetar, tente novamente.')
      } else {
        toast.error('Error interno, por favor tente em instantes.')
      }
    } finally {
      setOpenConfirmPasswordReset(false);
      setSelectedUser({});
      setLoading(false);
    }
  }

  function handleClose() {
    setOpenConfirmPasswordReset(false);
  }

  return (
    <div>
      <Dialog
        aria-describedby="alert-dialog-slide-description"
        aria-labelledby="alert-dialog-slide-title"
        keepMounted
        onClose={handleClose}
        open={openConfirmPasswordReset}
      >
        <DialogTitle id="alert-dialog-slide-title">{'Confirmação'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Deseja resetar a senha de {selectedUser.username || ''}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={handleClose}>
            Cancelar
          </Button>
          <Button
            color="primary"
            onClick={handleResetPassword}>
            {loading ? <CircularProgress /> : 'Confirmar'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ResetPassword.propTypes = {
  openConfirmPasswordReset: PropTypes.bool,
  selectedUser: PropTypes.object,
  setOpenConfirmPasswordReset: PropTypes.func,
  setSelectedUser: PropTypes.func
};
