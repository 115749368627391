import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import MaterialTable from 'material-table';
import { toast } from 'react-toastify';

import { ConstructionToolbar } from '../components';
import ConstructionCreate from '../ConstructionCreate';
import ConstructionUpdate from '../ConstructionUpdate';
import api from '../../../services/api';
import useDebounce from 'hooks/useDebounce';
import { Box, Typography } from '@material-ui/core';
import SearchInput from 'components/SearchInput';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
    background: '#fff'
  },
  table: {
    boxShadow: 'none',
    background: '#fff'
  }
}));

export default function ConstructionList() {
  const classes = useStyles();

  const [constructions, setConstructions] = useState({
    columns: [
      { title: 'Nome', field: 'name_construction' },
      { title: 'Companhia', field: 'customer.name' },
      { title: 'Telefone', field: 'customer.phone' }
    ],
    data: []
  });
  const [customers, setCustomers] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [openFormUpdate, setOpenFormUpdate] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [query, setQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
    total: 0,
    lastPage: 0
  });

  const debouncedSearch = useDebounce(handleSearchChange, 800);

  function handleSearchChange(searchTerm) {
    setQuery(searchTerm);
    loadConstructions(1, pagination.perPage, searchTerm);
  }

  useEffect(() => {
    loadConstructions();
    // eslint-disable-next-line
  }, []);

  async function loadConstructions(
    page = pagination.page,
    perPage = pagination.perPage,
    searchTerm = query
  ) {
    try {
      setIsLoading(true);

      const endpoint = searchTerm
        ? `constructions/search/${searchTerm}`
        : 'constructions';

      const { data } = await api.get(endpoint, {
        params: { page, limit: perPage }
      });

      setPagination({
        page: data.page,
        perPage: data.perPage,
        total: data.total,
        lastPage: data.lastPage
      });

      const responseCustomers = await api.get('customers/companies');

      const dataCustomers = responseCustomers.data.map(customer => ({
        value: customer,
        label: customer.name
      }));

      setConstructions(prevState => ({ ...prevState, data: data.data }));
      setCustomers(dataCustomers);
    } catch (error) {
      toast.error('Falha ao carregar os dados.');
    } finally {
      setIsLoading(false);
    }
  }

  async function deleteConstructions(construction) {
    try {
      const { data } = await api.delete(`constructions/${construction.id}`);

      if (data.success) {
        toast.success(data.success.message);
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.error.message);
      } else {
        toast.error('Error interno, por favor tente em instantes.');
      }
    } finally {
      loadConstructions();
    }
  }

  async function editContruction(data) {
    await setSelectedUser(data);
    setOpenFormUpdate(true);
  }

  function handleClearSearch() {
    setQuery('');
    loadConstructions(1, pagination.perPage, '');
  }

  return (
    <div className={classes.root}>
      <ConstructionToolbar setOpenForm={setOpenForm} />
      <div className={classes.content}>
        <Box borderRadius={4} className={classes.toolbar}>
          <Typography variant="h6">Obras</Typography>
          <SearchInput
            onChange={e => {
              setQuery(e.target.value);
              debouncedSearch(e.target.value);
            }}
            onClear={handleClearSearch}
            placeholder="Buscar obra"
            value={query}
          />
        </Box>
        <MaterialTable
          actions={[
            {
              icon: 'create',
              tooltip: 'Editar obra',
              onClick: (_, rowData) => editContruction(rowData)
            }
          ]}
          columns={constructions.columns}
          components={{
            Container: props => <div {...props} className={classes.table} />
          }}
          data={constructions.data}
          editable={{
            onRowDelete: oldData =>
              new Promise(resolve => {
                setTimeout(() => {
                  resolve();
                  const data = [...constructions.data];
                  const constructionSelected = data[data.indexOf(oldData)];
                  deleteConstructions(constructionSelected);
                }, 600);
              })
          }}
          isLoading={isLoading}
          localization={{
            header: {
              actions: 'Ações'
            },
            body: {
              emptyDataSourceMessage: 'Não há registros a serem exibidos',
              deleteTooltip: 'Deletar obra',
              editTooltip: 'Editar obra',
              editRow: {
                saveTooltip: 'Confirmar',
                cancelTooltip: 'Cancelar',
                deleteText: 'Tem certeza de que deseja excluir este obra?'
              }
            },
            toolbar: {
              searchPlaceholder: 'Buscar obra',
              searchTooltip: 'Buscar'
            },
            pagination: {
              labelRowsSelect: 'linhas',
              labelDisplayedRows: '{from}-{to} de {count}',
              firstTooltip: 'Primeira página',
              lastTooltip: 'Última página',
              previousTooltip: 'Página anterior',
              nextTooltip: 'Próxima página'
            }
          }}
          onChangePage={newPage => {
            setPagination(prev => ({ ...prev, page: newPage + 1 }));
            loadConstructions(newPage + 1, pagination.perPage, query);
          }}
          onChangeRowsPerPage={newPerPage => {
            setPagination(prev => ({ ...prev, perPage: newPerPage, page: 1 }));
            loadConstructions(1, newPerPage, query);
          }}
          options={{
            pageSize: pagination.perPage,
            search: false,
            toolbar: false,
            pageSizeOptions: [5, 10, 20]
          }}
          page={pagination.page - 1}
          totalCount={pagination.total}
        />
      </div>
      <ConstructionCreate
        customers={customers}
        loadConstructions={loadConstructions}
        openForm={openForm}
        setOpenForm={setOpenForm}
      />
      <ConstructionUpdate
        customers={customers}
        loadConstructions={loadConstructions}
        openForm={openFormUpdate}
        selectedUser={selectedUser}
        setOpenForm={setOpenFormUpdate}
      />
    </div>
  );
}
