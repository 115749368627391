import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import MaterialTable from 'material-table';
import { toast } from 'react-toastify';

import { RequesterToolbar } from '../components';
import RequesterCreate from '../RequesterCreate';
import RequesterUpdate from '../RequesterUpdate';
import api from '../../../services/api';
import useDebounce from 'hooks/useDebounce';
import { Box, Typography } from '@material-ui/core';
import SearchInput from 'components/SearchInput';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
    background: '#fff'
  },
  table: {
    boxShadow: 'none',
    background: '#fff'
  }
}));

export default function RequesterList() {
  const classes = useStyles();

  const [requesters, setRequesters] = useState({
    columns: [
      { title: 'Nome', field: 'name_requester' },
      { title: 'Empresa', field: 'customer.name' }
    ],
    data: []
  });
  const [customers, setCustomers] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [openFormUpdate, setOpenFormUpdate] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [query, setQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
    total: 0,
    lastPage: 0
  });

  const debouncedSearch = useDebounce(handleSearchChange, 800);

  function handleSearchChange(searchTerm) {
    setQuery(searchTerm);
    loadRequesters(1, pagination.perPage, searchTerm);
  }

  useEffect(() => {
    loadRequesters();
    // eslint-disable-next-line
  }, []);

  async function loadRequesters(
    page = pagination.page,
    perPage = pagination.perPage,
    searchTerm = query
  ) {
    try {
      setIsLoading(true);

      const endpoint = searchTerm
        ? `requesters/search/${searchTerm}`
        : 'requesters';

      const { data } = await api.get(endpoint, {
        params: { page, limit: perPage }
      });

      setPagination({
        page: data.page,
        perPage: data.perPage,
        total: data.total,
        lastPage: data.lastPage
      });

      const responseCustomers = await api.get('customers/companies');

      const dataCustomers = responseCustomers.data.map(customer => ({
        value: customer,
        label: customer.name
      }));

      setRequesters(prevState => ({ ...prevState, data: data.data }));
      setCustomers(dataCustomers);
    } catch (error) {
      toast.error('Falha ao carregar os dados.');
    } finally {
      setIsLoading(false);
    }
  }

  async function deleteRequester(requester) {
    try {
      const { data } = await api.delete(`requesters/${requester.id}`);

      if (data.success) {
        toast.success(data.success.message);
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.error.message);
      } else {
        toast.error('Error interno, por favor tente em instantes.');
      }
    } finally {
      loadRequesters();
    }
  }

  async function editRequester(data) {
    await setSelectedUser(data);
    setOpenFormUpdate(true);
  }

  function handleClearSearch() {
    setQuery('');
    loadRequesters(1, pagination.perPage, '');
  }

  return (
    <div className={classes.root}>
      <RequesterToolbar setOpenForm={setOpenForm} />
      <div className={classes.content}>
        <Box borderRadius={4} className={classes.toolbar}>
          <Typography variant="h6">Solicitantes</Typography>
          <SearchInput
            onChange={e => {
              setQuery(e.target.value);
              debouncedSearch(e.target.value);
            }}
            onClear={handleClearSearch}
            placeholder="Buscar solicitante"
            value={query}
          />
        </Box>
        <MaterialTable
          actions={[
            {
              icon: 'create',
              tooltip: 'Editar solicitante',
              onClick: (_, rowData) => editRequester(rowData)
            }
          ]}
          columns={requesters.columns}
          components={{
            Container: props => <div {...props} className={classes.table} />
          }}
          data={requesters.data}
          editable={{
            onRowDelete: oldData =>
              new Promise(resolve => {
                setTimeout(() => {
                  resolve();
                  const data = [...requesters.data];
                  const requesterSelected = data[data.indexOf(oldData)];
                  deleteRequester(requesterSelected);
                }, 600);
              })
          }}
          isLoading={isLoading}
          localization={{
            header: {
              actions: 'Ações'
            },
            body: {
              emptyDataSourceMessage: 'Não há registros a serem exibidos',
              deleteTooltip: 'Deletar solicitante',
              editTooltip: 'Editar solicitante',
              editRow: {
                saveTooltip: 'Confirmar',
                cancelTooltip: 'Cancelar',
                deleteText:
                  'Tem certeza de que deseja excluir este solicitante?'
              }
            },
            toolbar: {
              searchPlaceholder: 'Buscar solicitante',
              searchTooltip: 'Buscar'
            },
            pagination: {
              labelRowsSelect: 'linhas',
              labelDisplayedRows: '{from}-{to} de {count}',
              firstTooltip: 'Primeira página',
              lastTooltip: 'Última página',
              previousTooltip: 'Página anterior',
              nextTooltip: 'Próxima página'
            }
          }}
          onChangePage={newPage => {
            setPagination(prev => ({ ...prev, page: newPage + 1 }));
            loadRequesters(newPage + 1, pagination.perPage, query);
          }}
          onChangeRowsPerPage={newPerPage => {
            setPagination(prev => ({ ...prev, perPage: newPerPage, page: 1 }));
            loadRequesters(1, newPerPage, query);
          }}
          options={{
            pageSize: pagination.perPage,
            search: false,
            toolbar: false,
            pageSizeOptions: [5, 10, 20]
          }}
          page={pagination.page - 1}
          title="Solicitantes"
          totalCount={pagination.total}
        />
      </div>
      <RequesterCreate
        customers={customers}
        loadRequesters={loadRequesters}
        openForm={openForm}
        setOpenForm={setOpenForm}
      />
      <RequesterUpdate
        customers={customers}
        loadRequesters={loadRequesters}
        openForm={openFormUpdate}
        selectedUser={selectedUser}
        setOpenForm={setOpenFormUpdate}
      />
    </div>
  );
}
