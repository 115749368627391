import React from 'react';
import PropTypes from 'prop-types';
import {
  Document,
  Page,
  View,
  StyleSheet,
  Image,
  Text
} from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#fff',
    padding: 30
  },
  imageLogo: {
    width: 200
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  contactInformation: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 20
  },
  contactText: {
    fontSize: 12
  },
  containerTitle: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 20
  },
  title: {
    fontSize: 18,
    fontWeight: 800
  },
  budgetInformation: {
    fontSize: 12,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: 20
  },
  table: {
    display: 'table',
    marginHorizontal: 5,
    marginVertical: 20,
    flexDirection: 'column'
  },
  tableSubInformation: {
    display: 'table',
    marginHorizontal: 5,
    marginVertical: 1,
    flexDirection: 'row'
  },
  tableRow: {
    width: '100%',
    flexDirection: 'row'
  },
  tableColHeader: {
    padding: 5,
    border: 1,
    width: '25%',
    textAlign: 'center'
  },
  tableSubInformationTotal: {
    padding: 5,
    border: 1,
    width: '75%',
    textAlign: 'right'
  },
  tableCellHeader: {
    fontSize: 12
  },
  tableCell: {
    fontSize: 11
  },
  footer: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey'
  }
});

export default function PdfDocument({ productData }) {
  return (
    <Document
      subject={productData.customer && productData.customer.name}
      title="Orçamento">
      <Page
        size="A4"
        style={styles.page}>
        <View style={styles.header}>
          <View>
            <Image
              source="/images/logos/logo-black.jpg"
              style={styles.imageLogo}
            />
          </View>
          <View style={styles.contactInformation}>
            <Text style={styles.contactText}>
              Av. Ailton Gomes de Alencar, 1448 - Pirajá
            </Text>
            <Text style={styles.contactText}>Juazeiro do Norte - CE</Text>
            <Text style={styles.contactText}>98853-2791</Text>
            <Text style={styles.contactText}>contato.newplot@gmail.com</Text>
          </View>
        </View>
        <View style={styles.containerTitle}>
          <Text style={styles.title}>Orçamento</Text>
        </View>
        <View style={styles.budgetInformation}>
          <Text>
            Cliente: {productData.customer && productData.customer.name}
          </Text>
          {productData.requester && (
            <Text>Solicitante: {productData.requester.name_requester}</Text>
          )}
          {productData.construction && (
            <Text>Obra: {productData.construction.name_construction}</Text>
          )}
        </View>
        <View style={styles.budgetInformation}>
          <Text>
            Quantidade de itens:{' '}
            {productData.amountOfBoards && productData.amountOfBoards}
          </Text>
          <Text>
            Total do orçamento:{' '}
            {productData.amountFormatted && productData.amountFormatted}
          </Text>
        </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Item</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Quantidade</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Valor unitário</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Valor total</Text>
            </View>
          </View>
          <View>
            {productData.products &&
              productData.products.map(product => (
                <View
                  key={product.id}
                  style={styles.tableRow}>
                  <View style={styles.tableColHeader}>
                    <Text style={styles.tableCell}>{product.name}</Text>
                  </View>
                  <View style={styles.tableColHeader}>
                    <Text style={styles.tableCell}>
                      {product.pivot.quantity}
                    </Text>
                  </View>
                  <View style={styles.tableColHeader}>
                    <Text style={styles.tableCell}>
                      {product.priceFormatted}
                    </Text>
                  </View>
                  <View style={styles.tableColHeader}>
                    <Text style={styles.tableCell}>
                      {product.totalValuePerProduct}
                    </Text>
                  </View>
                </View>
              ))}
          </View>
        </View>
        <View style={styles.tableSubInformation}>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Total bruto</Text>
            </View>
            <View style={styles.tableSubInformationTotal}>
              <Text style={styles.tableCellHeader}>
                {productData.amountFormatted &&
                  productData.amountOriginalFormatted}
              </Text>
            </View>
          </View>
        </View>
        {productData.discount && (
          <>
            <View style={styles.tableSubInformation}>
              <View style={styles.tableRow}>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>
                    Desconto({productData.discount.percentage}%)
                  </Text>
                </View>
                <View style={styles.tableSubInformationTotal}>
                  <Text style={styles.tableCellHeader}>
                    {productData.discount.discountFormatted}
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.tableSubInformation}>
              <View style={styles.tableRow}>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>Total calculado</Text>
                </View>
                <View style={styles.tableSubInformationTotal}>
                  <Text style={styles.tableCellHeader}>
                    {productData.amountFormatted}
                  </Text>
                </View>
              </View>
            </View>
          </>
        )}
        <View style={styles.footer}>
          <Text>
            Juazeiro do Norte - CE, gerado em {productData.dateFormatted}
          </Text>
        </View>
      </Page>
    </Document>
  );
}

PdfDocument.propTypes = {
  productData: PropTypes.object
};
