import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(() => ({
  typography: {
    textAlign: 'center',
    height: '100vh'
  },
  box: {
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

export default function TabPanel({ children, value, index, ...other }) {
  const classes = useStyles();

  return (
    <Typography
      aria-labelledby={`full-width-tab-${index}`}
      className={classes.typography}
      component="div"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      role="tabpanel"
      {...other}>
      {value === index && (
        <Box
          className={classes.box}
          p={6}>
          {children}
        </Box>
      )}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};
