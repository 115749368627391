import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import Form from './Form';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
}));

export default function ConstructionUpdate({
  openForm,
  setOpenForm,
  loadConstructions,
  customers,
  selectedUser
}) {
  const classes = useStyles();

  const [data, setData] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });
  const [value, setValue] = useState({});

  useEffect(() => {
    setData({
      ...data,
      values: { ...data.values, nome: selectedUser.name_construction }
    });
    setValue({
      ...value,
      value: { id: selectedUser.customer && selectedUser.customer.id }
    });
    // eslint-disable-next-line
  }, [selectedUser]);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid
          container
          spacing={3}>
          <Form
            customers={customers}
            data={data}
            loadConstructions={loadConstructions}
            openForm={openForm}
            selectedUser={selectedUser}
            setData={setData}
            setOpenForm={setOpenForm}
            setValue={setValue}
            value={value}
          />
        </Grid>
      </div>
    </div>
  );
}

ConstructionUpdate.propTypes = {
  customers: PropTypes.array,
  loadConstructions: PropTypes.func,
  openForm: PropTypes.bool,
  selectedUser: PropTypes.object,
  setOpenForm: PropTypes.func
};
